import {
  PATH_ACCOUNT_ADD_PRODUCTS,
  PATH_ACCOUNT_BILLING_HISTORY,
  PATH_ACCOUNT_CANCEL_LICENSES,
  PATH_ACCOUNT_CHANGE_PLAN,
  PATH_ACCOUNT_CHANGE_SPECIFIC_PLAN,
  PATH_ACCOUNT_CONTRACT_DETAILS,
  PATH_ACCOUNT_ORGANIZATION_DETAILS,
  PATH_ACCOUNT_OVERVIEW,
  PATH_ACCOUNT_ROOT,
} from 'features/account/routing/accountPaths';

const accountRouteMigration = [
  {
    stateName: 'account.account',
    url: PATH_ACCOUNT_ROOT,
    useReactRoute: 'temp_react_port_account',
  },
  {
    stateName: '', // leaving this blank because there's no corresponding angular state for this path.
    url: PATH_ACCOUNT_CONTRACT_DETAILS,
    useReactRoute: 'temp_react_port_account',
  },
  {
    stateName: 'account.overview',
    url: PATH_ACCOUNT_OVERVIEW,
    useReactRoute: 'temp_react_port_account',
  },
  {
    stateName: 'account.billing-history',
    url: PATH_ACCOUNT_BILLING_HISTORY,
    useReactRoute: 'temp_react_port_account',
  },
  {
    stateName: 'account.organization-details',
    url: PATH_ACCOUNT_ORGANIZATION_DETAILS,
    useReactRoute: 'temp_react_port_account_organization_details',
  },
  {
    stateName: 'account.account.cancel-licenses',
    url: PATH_ACCOUNT_CANCEL_LICENSES,
    useReactRoute: 'temp_react_port_account',
  },
  {
    stateName: 'account.account.add-products',
    url: PATH_ACCOUNT_ADD_PRODUCTS,
    useReactRoute: 'temp_react_port_account',
  },
  {
    stateName: 'account.account.change-plan',
    url: PATH_ACCOUNT_CHANGE_PLAN,
    useReactRoute: 'temp_react_port_account',
  },
  {
    stateName: 'account.account.change-specific-plan',
    url: PATH_ACCOUNT_CHANGE_SPECIFIC_PLAN,
    useReactRoute: 'temp_react_port_account',
  },
];

export default accountRouteMigration;
