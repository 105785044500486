/* eslint-disable id-length -- paths have long names */
import {BULK_OPERATION_MODAL_ID} from 'common/components/bulk-operation/bulk-operation-utils/bulkOperationConstants';

/**
 * React paths for the Users section.
 * During migration, the section headers below contain the mapping of the Angular router states to the React router paths.
 */
const PATH_USERS_ROOT = '/:orgId/users';

/**
 * Users.
 *
 * 'users'
 * 'users.users' => abstract state for 'users.users.all-users'
 * 'users.users.all-users' => PATH_USERS
 *
 * 'users.jobs' => PATH_USERS_BULK_OPERATIONS
 * 'users.results' => PATH_USERS_BULK_OPERATIONS_RESULTS
 * 'users.license-deficit-report' => PATH_USERS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT
 *
 * 'users.user-details' => PATH_USER_DETAILS
 */
const PATH_USERS = PATH_USERS_ROOT;

const PATH_USERS_BULK_OPS_MODAL = `${PATH_USERS}/${BULK_OPERATION_MODAL_ID}`;
// mode is route for one of BULK_OPERATION_MODE
const PATH_USERS_BULK_OPS_MODAL_MODE = `${PATH_USERS_BULK_OPS_MODAL}/:mode`;

const PATH_USERS_BULK_OPERATIONS = `${PATH_USERS}/bulk-operations`;
const PATH_USERS_BULK_OPERATIONS_RESULTS = `${PATH_USERS}/bulk-operations/:jobId`;
const PATH_USERS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT = `${PATH_USERS}/bulk-operations/:jobId/deficit`;

const PATH_USER_DETAILS = `${PATH_USERS}/:userId`;

const PATH_SEGMENT_REVIEW_USER_INTRODUCTIONS = 'review-user-introductions';
const PATH_USERS_REVIEW_USER_INTRODUCTIONS = `${PATH_USERS}/${PATH_SEGMENT_REVIEW_USER_INTRODUCTIONS}`;
const PATH_SEGMENT_REVIEW_SAVED_USER_INTRODUCTIONS = 'review-saved-user-introductions';
const PATH_USERS_REVIEW_SAVED_USER_INTRODUCTIONS = `${PATH_USERS}/${PATH_SEGMENT_REVIEW_SAVED_USER_INTRODUCTIONS}`;
const PATH_SEGMENT_ADD_USERS_TO_ORG = 'add-users-to-org';
const PATH_USERS_ADD_USERS_TO_ORG = `${PATH_USERS}/${PATH_SEGMENT_ADD_USERS_TO_ORG}`;
const PATH_SEGMENT_EDIT_PRODUCTS_AND_USER_GROUPS = 'edit-products-and-user-groups';
const PATH_USERS_EDIT_PRODUCTS_AND_USER_GROUPS = `${PATH_USERS}/${PATH_SEGMENT_EDIT_PRODUCTS_AND_USER_GROUPS}`;
const PATH_USERS_EDIT_PRODUCTS_AND_USER_GROUPS_SPECIFIC = `${PATH_USERS_EDIT_PRODUCTS_AND_USER_GROUPS}/:memberId`;

/**
 * Administrators.
 *
 * 'users.administrators' => abstract state for 'users.administrators.all-admins'
 * 'users.administrators.all-admins' => PATH_ADMINISTRATORS
 * 'users.administrator-details' => PATH_ADMINISTRATOR_DETAILS
 */
const PATH_ADMINISTRATORS = `${PATH_USERS}/administrators`;
const PATH_ADMINISTRATOR_DETAILS = `${PATH_USERS}/administrators/:userId`;

/**
 * Developers.
 *
 * 'users.developers' => abstract state for  'users.developers.all-developers'
 * 'users.developers.all-developers' => PATH_DEVELOPERS
 * non-existant => PATH_ADMINISTRATOR_DETAILS
 */
const PATH_DEVELOPERS = `${PATH_USERS}/developers`;
const PATH_DEVELOPER_DETAILS = `${PATH_DEVELOPERS}/:userId`;

/**
 * User groups.
 *
 * 'users.user-groups' => PATH_USER_GROUPS
 * 'users.user-group-bulk-operations' => PATH_USER_GROUPS_BULK_OPERATIONS
 * 'users.user-group-bulk-operations-results' => PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS
 *
 * 'users.user-group-details' => PATH_USER_GROUP_DETAILS
 * 'users.user-group-details.admins' => PATH_USER_GROUP_DETAILS_ADMINS
 * 'users.user-group-details.users' => PATH_USER_GROUP_DETAILS_USERS
 * 'users.user-group-details.assigned-product-profiles' => PATH_USER_GROUP_DETAILS_ASSIGNED_PRODUCT_PROFILES
 *
 * 'users.user-group-jobs' => PATH_USER_GROUP_DETAILS_BULK_OPERATIONS
 * 'users.user-group-results' => PATH_USER_GROUP_DETAILS_RESULTS
 * 'users.user-group-license-deficit-report' => PATH_USER_GROUP_DETAILS_LICENSE_DEFICIT_REPORT
 */
const PATH_USER_GROUPS = `${PATH_USERS}/user-groups`;
const PATH_USER_GROUPS_BULK_OPERATIONS = `${PATH_USER_GROUPS}/bulk-operations`;
const PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS = `${PATH_USER_GROUPS}/bulk-operations/:jobId`;

const PATH_USER_GROUP_DETAILS = `${PATH_USER_GROUPS}/:userGroupId`;

const USER_GROUP_DETAILS_TAB_NAV = {
  ADMINS: 'admins',
  ASSIGNED_PROFILES: 'assigned-product-profiles',
  USERS: 'users',
};
const PATH_USER_GROUP_DETAILS_ADMINS = `${PATH_USER_GROUP_DETAILS}/${USER_GROUP_DETAILS_TAB_NAV.ADMINS}`;
const PATH_USER_GROUP_DETAILS_USERS = `${PATH_USER_GROUP_DETAILS}/${USER_GROUP_DETAILS_TAB_NAV.USERS}`;
const PATH_USER_GROUP_DETAILS_ASSIGNED_PRODUCT_PROFILES = `${PATH_USER_GROUP_DETAILS}/${USER_GROUP_DETAILS_TAB_NAV.ASSIGNED_PROFILES}`;

const PATH_USER_GROUP_DETAILS_BULK_OPERATIONS = `${PATH_USER_GROUP_DETAILS}/bulk-operations`;
const PATH_USER_GROUP_DETAILS_RESULTS = `${PATH_USER_GROUP_DETAILS}/bulk-operations/:jobId`;
const PATH_USER_GROUP_DETAILS_LICENSE_DEFICIT_REPORT = `${PATH_USER_GROUP_DETAILS}/bulk-operations/:jobId/deficit`;

/**
 * Directory users.
 *
 * 'users.owned-directory-list' => PATH_DIRECTORIES
 *
 * 'users.directory-users' => abstract state for 'users.directory-users.all-users'
 * 'users.directory-users.all-users' => PATH_DIRECTORY_USERS
 *
 * 'users.directory-user-details' => PATH_DIRECTORY_USER_DETAILS
 * 'users.directory-user-jobs' => PATH_DIRECTORY_USER_BULK_OPERATIONS
 * 'users.directory-user-results' => PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS
 */
const PATH_DIRECTORIES = `${PATH_USERS}/directory-users`;
const PATH_DIRECTORY_USERS = `${PATH_DIRECTORIES}/:directoryId`;
const PATH_DIRECTORY_USER_DETAILS = `${PATH_DIRECTORY_USERS}/:userId`;
const PATH_DIRECTORY_USER_BULK_OPERATIONS = `${PATH_DIRECTORY_USERS}/bulk-operations`;
const PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS = `${PATH_DIRECTORY_USERS}/bulk-operations/:jobId`;

/**
 * Api credentials.
 *
 * 'users.integrations' => abstract state for 'users.integrations.all-integrations'
 * 'users.integrations.all-integrations' => PATH_API_CREDENTIALS
 */
const PATH_API_CREDENTIALS = `${PATH_USERS}/integrations`;

export {
  PATH_ADMINISTRATORS,
  PATH_ADMINISTRATOR_DETAILS,
  PATH_API_CREDENTIALS,
  PATH_DEVELOPERS,
  PATH_DEVELOPER_DETAILS,
  PATH_DIRECTORIES,
  PATH_DIRECTORY_USERS,
  PATH_DIRECTORY_USER_DETAILS,
  PATH_DIRECTORY_USER_BULK_OPERATIONS,
  PATH_DIRECTORY_USER_BULK_OPERATIONS_RESULTS,
  PATH_SEGMENT_ADD_USERS_TO_ORG,
  PATH_SEGMENT_REVIEW_USER_INTRODUCTIONS,
  PATH_SEGMENT_REVIEW_SAVED_USER_INTRODUCTIONS,
  PATH_SEGMENT_EDIT_PRODUCTS_AND_USER_GROUPS,
  PATH_USERS,
  PATH_USERS_ADD_USERS_TO_ORG,
  PATH_USERS_BULK_OPERATIONS,
  PATH_USERS_BULK_OPERATIONS_RESULTS,
  PATH_USERS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_USERS_BULK_OPS_MODAL,
  PATH_USERS_BULK_OPS_MODAL_MODE,
  PATH_USERS_REVIEW_USER_INTRODUCTIONS,
  PATH_USERS_REVIEW_SAVED_USER_INTRODUCTIONS,
  PATH_USERS_EDIT_PRODUCTS_AND_USER_GROUPS,
  PATH_USERS_EDIT_PRODUCTS_AND_USER_GROUPS_SPECIFIC,
  PATH_USER_DETAILS,
  PATH_USER_GROUPS,
  PATH_USER_GROUPS_BULK_OPERATIONS,
  PATH_USER_GROUPS_BULK_OPERATIONS_RESULTS,
  PATH_USER_GROUP_DETAILS,
  PATH_USER_GROUP_DETAILS_ADMINS,
  PATH_USER_GROUP_DETAILS_ASSIGNED_PRODUCT_PROFILES,
  PATH_USER_GROUP_DETAILS_USERS,
  PATH_USER_GROUP_DETAILS_BULK_OPERATIONS,
  PATH_USER_GROUP_DETAILS_LICENSE_DEFICIT_REPORT,
  PATH_USER_GROUP_DETAILS_RESULTS,
  USER_GROUP_DETAILS_TAB_NAV,
};

/* eslint-enable id-length -- paths have long names */
