import {feature} from '@admin-tribe/acsc';

/**
 * NOTE:
 * This file is added just for the fallback to figid in case aicode is not available.
 * This case won't happen on PROD environment as in PROD all licenses have aicode.
 * This fallback is temporary time being until all licenses have aicode on STAGE environment.
 *
 * This file will be removed once all licenses have aicode on STAGE environment.
 */

// Constants for identifier values
const FIG_ID = 'figId';
const ADMINISTRABLE_ITEM_CODE = 'administrableItemCode';

// Variable to hold the calculated final value
let validKey;

/**
 * Calculates and sets the valid product group identifier based on feature flags and product list validation.
 * Below method is called only once and sets the validKey to either FIG_ID or ADMINISTRABLE_ITEM_CODE
 *
 * @param {Object} productList - The list of products, each with an `administrableItemCode` property.
 */
const determineValidId = (productList) => {
  // Check if the feature flag is enabled to fallback to FIG_ID
  if (!feature.isEnabled('temp_fallback_to_figid')) {
    validKey = ADMINISTRABLE_ITEM_CODE;
    return;
  }

  // Check if all products have a valid 'administrableItemCode'
  const hasValidAdministrableItemCodes = productList.items.every(
    (item) => item.administrableItemCode !== null && item.administrableItemCode !== undefined
  );

  // Set the final product group ID based on validation
  if (hasValidAdministrableItemCodes) {
    validKey = ADMINISTRABLE_ITEM_CODE;
  } else {
    validKey = FIG_ID;
  }
};

/**
 * Retrieves the final calculated product group identifier.
 *
 * @returns {string} - Returns the pre-calculated product group identifier.
 */
const getValidKey = () => validKey;

export {determineValidId, getValidKey};
