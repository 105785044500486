/* eslint-disable max-lines -- this file requires more lines */
import {eventBus, feature, log, navBus} from '@admin-tribe/acsc';
import {showError, showSuccess, showWarning, translateString} from '@admin-tribe/acsc-ui';
import cloneDeep from 'lodash/cloneDeep';
import {generatePath} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';

import rootStore from 'core/RootStore';
import {PATH_ALL_PACKAGES} from 'features/packages/routing/packagesPaths';

import {isMacTypePlatform} from '../adminToolsUtils';
import {postPackageCustomizationRequest} from '../api/tronData';
import {CREATE_PACKAGE, CREATE_PACKAGE_CONSTANTS, KCCC_SAP_CODE} from '../packagesConstants';

import AdminPackages from './PackagesAdminService';
import PackagesAdobeProductsUi from './PackagesAdobeProductsUiService';
import PackagesEntitlements from './PackagesEntitlementsService';
import PackagesExtensions from './PackagesExtensionsService';
import {
  extractLatestProductKeys,
  getCustomerType,
  getTemplateType,
  getUpdatedProductKeysSet,
  packagedProductsFilter,
  setFRLParameters,
  shouldCapturePluginDataInUpdatePackage,
  triggerIngestEvent,
} from './createPackageServiceUtils';
import {getProductCountForUpdateWorkflow} from './serviceUtils';

class CreatePackageService {
  /**
   * @description Method called when generic create package flow should be initiated
   *
   */
  static setCreatePackageFactoryValues() {
    this.resetParameters();
  }

  /**
   * @description Method called when First time user experience named create package flow should be initiated
   *
   */
  static setCreatePackageFactoryValuesForNamed() {
    this.resetParameters();
    this.isFueNamedPackageCreation = true;
  }

  /**
   * @description Method called when First time user experience frl create package flow should be initiated
   *
   */
  static setCreatePackageFactoryValuesForFrl() {
    this.resetParameters();
    this.isFueFrlPackageCreation = true;
  }

  /**
   * @description Method called when First time user experience nuell create package flow should be initiated
   *
   */
  static setCreatePackageFactoryValuesForNuell() {
    this.resetParameters();
    this.isFueNuellPackageCreation = true;
  }

  /**
   * @description Resets params which control the create package flow to be initiated
   *
   */
  static resetParameters() {
    this.isTemplatizedPackageCreation = false;
    this.isSingleAppPackageCreation = false;
    this.isFueNamedPackageCreation = false;
    this.isFueFrlPackageCreation = false;
    this.isFueNuellPackageCreation = false;
    this.isAccRequiredInTemplate = true;
    this.showExtraProductsInTemplates = true;
    this.isNonCCTpl = false;
    this.isAcrobatOnlyTpl = false;
    this.packageTemplate = {};
  }

  /**
   * @description Method to open the template view of create package workflow
   *
   * @param {Object} template - PackagesAdobeTemplateEntity object that is prefilled in create package workflow
   * @param {Boolean} isAccRequired - do we need to show Adobe Creative Cloud in template
   * @param {Boolean} showExtraProd - whether we want to show products other that are inside template or not
   */
  static setCreatePackageFactoryValuesForTemplate(template, isAccRequired, showExtraProd) {
    this.resetParameters();
    this.isTemplatizedPackageCreation = true;
    this.isSingleAppPackageCreation = template?.isSingleApp || false;
    this.isFueFrlPackageCreation = false;
    this.isFueNamedPackageCreation = false;
    this.isAccRequiredInTemplate = isAccRequired;
    this.showExtraProductsInTemplates = showExtraProd;
    this.isNonCCTpl = this.isNonCCPackageTemplate(template);
    this.isAcrobatOnlyTpl = template.isAcrobatOnlyTemplate;
    this.packageTemplate = cloneDeep(template);
  }

  /**
   * @description Method returns value that indicates if we need to show Adobe Creative Cloud in template
   *
   * @returns {Boolean} value that indicates if we need to show Adobe Creative Cloud in template
   */
  static isAccRequiredInTemplateView() {
    return this.isAccRequiredInTemplate;
  }

  /**
   * @description Method returns value that indicates if we need to show extra products in template
   *
   * @returns {Boolean} value that indicates if we need to show extra products in template
   */
  static shouldShowExtraProductsInTemplateView() {
    return this.showExtraProductsInTemplates;
  }

  /**
   * @description Method returns value that indicates if template is nonCC
   *
   * @returns {Boolean} value that indicates if template is nonCC
   */
  static isNonCCTemplate() {
    return this.isNonCCTpl;
  }

  /**
   * @description Method returns value that indicates if create package flow initiated is templatize package creation flow
   *
   * @returns {Boolean} value that indicates if create package flow initiated is templatize package creation flow
   */
  static isTemplatizedPackageCreate() {
    return this.isTemplatizedPackageCreation;
  }

  /**
   * @description Method returns value that indicates if create package flow initiated is single application package creation flow
   *
   * @returns {Boolean} value that indicates if create package flow initiated is single application package creation flow
   */
  static isSingleAppPackageCreate() {
    return this.isSingleAppPackageCreation;
  }

  /**
   * @description Method returns value that indicates if create package flow initiated is first time user experience package creation flow for frl packaging mode
   *
   * @returns {Boolean} value that indicates if create package flow initiated is first time user experience package creation flow for frl packaging mode
   */
  static isFueFrlPackageCreate() {
    return this.isFueFrlPackageCreation;
  }

  /**
   * @description Method returns value that indicates if create package flow initiated is first time user experience package creation flow for named packaging mode
   *
   * @returns {Boolean} value that indicates if create package flow initiated is first time user experience package creation flow for named packaging mode
   */
  static isFueNamedPackageCreate() {
    return this.isFueNamedPackageCreation;
  }

  /**
   * @description Method returns value that indicates if create package flow initiated is first time user experience package creation flow for nuell packaging mode
   *
   * @returns {Boolean} value that indicates if create package flow initiated is first time user experience package creation flow for nuell packaging mode
   */
  static isFueNuellPackageCreate() {
    return this.isFueNuellPackageCreation;
  }

  /**
   * @description Method returns package template
   *
   * @returns {Object} PackagesAdobeTemplateEntity
   */
  static getPackageTemplate() {
    return this.packageTemplate;
  }

  /**
   * @description Method called when create package flow is to be closed
   *
   */
  static closeCreatePackage() {
    this.resetParameters();
  }

  /**
   * @description Method returns adobe products
   *
   * @returns {Array} adobe products
   */
  static getProducts() {
    return PackagesAdobeProductsUi.getAdobeProducts();
  }

  /**
   * @description check if the particular offer has at least one FI which has atleat one NGL enabled product
   *
   * @returns {Boolean} true or false base on if the offer is applicable
   */
  static hasApplicableFrlOffers() {
    if (
      !(
        PackagesEntitlements.hasFrlIsolatedEntitlement ||
        PackagesEntitlements.hasFrlOnlineEntitlement ||
        PackagesEntitlements.hasFrlLanEntitlement ||
        PackagesEntitlements.hasFrlOfflineEntitlement
      )
    ) {
      return false;
    }
    const frlEnabledProducts = PackagesAdobeProductsUi.getAllPackageableAdobeProducts().filter(
      (product) => product.frlEnabled
    );

    // filter those offers which have at least 1 packageable FRL enabled product
    const frls = PackagesEntitlements.getFrlOffers().some((offer) =>
      offer
        .getPackageableProductSapCodes()
        .some(
          (sapCode) =>
            frlEnabledProducts.some((product) => product.sapCode === sapCode) ||
            (rootStore.packagesUiConstantsStore.nonFfcProductMap?.[sapCode] &&
              feature.isEnabled('temp_packages_CF_ontron'))
        )
    );
    return frls;
  }

  /**
   * @description method to start the download of the selected template
   *
   * @param {Object} template - PackagesAdobeTemplateEntity object that needs to be prefilled in create package workflow
   * @param {Boolean} showAccInUI - show Adobe Creative Cloud in template
   */
  static async downloadTemplatizedPackage(template, showAccInUI) {
    const selectedProductSet = new Set();
    const packagedProds = template.getPackagedAppsForCreatePackage();
    packagedProds.forEach((product) => {
      if (product.getSapCode() !== KCCC_SAP_CODE) {
        selectedProductSet.add(`${product.sapCode}/${product.version}/${product.platform}`);
      }
    });
    const selectedProdArr = [...selectedProductSet];
    const packageCreateObj = {
      accAdminPrivileged: template.configurations.accAdminPrivileged,
      accDisableAutoAppUpdate: template.configurations.accDisableAutoAppUpdate,
      accDisabled: false,
      appPanelEnabled: template.configurations.appPanelEnabled,
      bits: template.bits,
      customerType: getCustomerType(),
      enableExtenstions: template.configurations.enableExtenstions,
      filesPanelEnabled: template.configurations.filesPanelEnabled,
      locale: template.configurations.locale,
      marketPanelEnabled: template.configurations.filesPanelEnabled,
      osLangDetection: template.configurations.osLangDetection,
      packageName: template.name,
      packageType: CREATE_PACKAGE_CONSTANTS.MANAGED_PACKAGE,
      platform: template.platform,
      rumEnabled: template.configurations.rumEnabled,
      selectedAdobeProductIds: selectedProdArr.map((product) => product),
      selectedProductAddOnsMap: {},
      selfServePluginsEnabled: template.configurations.selfServePluginsEnabled,
      updatesEnabled: template.configurations.updatesEnabled && showAccInUI,
    };
    packageCreateObj.goCartConsentText = translateString({
      id: 'packages.preferences.genuine.service.consentText',
    });
    packageCreateObj.orgCountryCode = rootStore.organizationStore.activeOrg.countryCode;
    packageCreateObj.orgMarketSegment = rootStore.organizationStore.activeOrg.marketSegment;
    // make acc disabled for DC packages and enabled for CC Packages
    if (PackagesEntitlements.hasDcOnlyEntitlement || !showAccInUI) {
      packageCreateObj.accDisabled = true;
      packageCreateObj.appPanelEnabled = false;
      packageCreateObj.accAdminPrivileged = false;
      packageCreateObj.selfServePluginsEnabled = false;
      packageCreateObj.accDisableAutoAppUpdate = true;
    } else if (feature.isEnabled('packages_disable_app_trial')) {
      packageCreateObj.disableAppTrial = template.configurations.disableAppTrial;
    }
    packageCreateObj.templateType = getTemplateType(this.isNonCCPackageTemplate(template), false);
    packageCreateObj.productsCount = template.getPackagedAppsCount();
    await this.postPackageCreationRequest({
      containsArchived: false,
      isUpdatesOnlyPackage: false,
      packageCreateObj,
      packagedPlugins: [],
      workflow: 'downloadTemplateWorkflow',
    });
  }

  /**
   * @description method to build the updated package with new parameters and latest products
   *
   * @param {Object} adminPackage - original package object that needs to be updated
   * @param {String} packageName - new name of the updated package
   * @param {Boolean} isUpdatesOnlyPackage - whether the package should contain only the updates or the already updated products as well
   */
  static async buildUpdatedPackage(adminPackage, packageName, isUpdatesOnlyPackage) {
    let packageCreateObj = {
      accAdminPrivileged: adminPackage.configurations.accAdminPrivileged,
      accDisableAutoAppUpdate: adminPackage.configurations.accDisableAutoAppUpdate,
      accDisabled: adminPackage.configurations.accSuppress,
      appPanelEnabled: adminPackage.configurations.appsPanelVisible,
      bits: adminPackage.bits,
      customerType: getCustomerType(),
      enableAALExtension: !!adminPackage.configurations.enableExtensionsAal,
      enableExtenstions: !!adminPackage.configurations.enableExtenstions,
      filesPanelEnabled: !!adminPackage.configurations.filesPanelEnabled,
      locale: adminPackage.language,
      marketPanelEnabled: !!adminPackage.configurations.filesPanelEnabled,
      osLangDetection: adminPackage.configurations.OSLangDetection,
      packageName,
      packageType: adminPackage.packageType,
      parentPackageID: adminPackage.packageId,
      platform: adminPackage.platform,
      rumEnabled: adminPackage.configurations.rumEnabled,
      selectedAdobeProductIds: cloneDeep([
        ...this.getSelectedAdobeProductIds(adminPackage.allPackagedProducts, isUpdatesOnlyPackage),
      ]),
      selfServePluginsEnabled: !!adminPackage.configurations.selfServePluginsEnabled,
      updatesEnabled: adminPackage.configurations.updateEnabled,
    };

    if (feature.isEnabled('temp_packages_management_options_fixes')) {
      packageCreateObj = {
        ...packageCreateObj,
        betaAppDownloadEnabled: adminPackage?.configurations?.betaAppDownloadEnabled,
        browserBasedAuthEnabled: adminPackage?.configurations?.browserBasedAuthEnabled,
      };
    }

    packageCreateObj.selectedProductAddOnsMap = cloneDeep({
      ...this.getUpdatedAddOnsMap(adminPackage, packageCreateObj.selectedAdobeProductIds),
    });

    if (adminPackage.isDeviceInformationShared) {
      packageCreateObj.deviceInformationShared = !!adminPackage.isDeviceInformationShared;
    }

    packageCreateObj.goCartConsentText = translateString({
      id: 'packages.preferences.genuine.service.consentText',
    });
    packageCreateObj.orgCountryCode = rootStore.organizationStore.activeOrg.countryCode;
    packageCreateObj.orgMarketSegment = rootStore.organizationStore.activeOrg.marketSegment;

    let packagedPlugins = [];
    if (shouldCapturePluginDataInUpdatePackage(adminPackage)) {
      packageCreateObj.pluginDownloadUrlApi = PackagesExtensions.getExtensionsDownloadUrl();
      packageCreateObj.plugins = adminPackage.pluginsInfo.plugins.map((plugin) => ({
        extensionId: plugin.id,
        type: plugin.type,
        versionId: plugin.versionId,
        versionString: plugin.version,
      }));
      packagedPlugins = adminPackage.pluginsInfo.plugins;
    }

    if (adminPackage.configurations.ausstOverride) {
      packageCreateObj.ausstOverride = adminPackage.configurations.ausstOverride;
    }
    if (adminPackage.configurations.userInstallDirectory) {
      packageCreateObj.userInstallDirectory = adminPackage.configurations.userInstallDirectory;
    }

    if (adminPackage.isFrlOrNuellPackage && adminPackage.frlPackagingInfo) {
      packageCreateObj.packageJobFrl = setFRLParameters(adminPackage, packageCreateObj);
    }

    if (feature.isEnabled('packages_disable_app_trial')) {
      packageCreateObj.disableAppTrial = adminPackage.configurations.disableAppTrial;
    }

    if (isMacTypePlatform(packageCreateObj.platform)) {
      packageCreateObj.flatPackageEnabled = !!adminPackage.flatPackageEnabled;
    }

    packageCreateObj.productsCount = getProductCountForUpdateWorkflow(
      adminPackage.packagedProducts,
      packageCreateObj.selectedAdobeProductIds
    );
    await this.postPackageCreationRequest({
      containsArchived: false,
      isUpdatesOnlyPackage,
      packageCreateObj,
      packagedPlugins,
      workflow: 'updatePackageWorkflow',
    });
  }

  /**
   * @description Retrieves list of products addons for update
   * @param {Array} adminPackage - admin package object
   * @param {Object} selectedAdobeProductIds products to update
   *
   * @returns {Array} Array of products addons for update
   */
  static getUpdatedAddOnsMap(adminPackage, selectedAdobeProductIds) {
    const updatedAddOnsMap = {};
    // remove KCCC and invisible products
    const packagedProducts = adminPackage.allPackagedProducts.filter(packagedProductsFilter);

    // seperate non-STI products
    const pointProducts = packagedProducts.filter((product) => !product.isSTI);

    // Update selectedAddOnsMap field
    pointProducts.forEach((product) => {
      if (
        selectedAdobeProductIds.includes(product.getLatestProductKey()) &&
        adminPackage.productAddOnMap &&
        adminPackage.productAddOnMap[product.product_key]
      ) {
        const modules = PackagesAdobeProductsUi.getAdobeProductBySapCodeVersionPlatform(
          product.getLatestProductKey()
        ).getModules();

        const updatedProductAddOnMap = [];
        adminPackage.productAddOnMap[product.product_key].forEach((addOn) => {
          if (modules.some((module) => module.id === addOn.id)) {
            updatedProductAddOnMap.push(addOn);
          }
        });

        updatedAddOnsMap[product.getLatestProductKey()] = updatedProductAddOnMap;
      }
    });

    return updatedAddOnsMap;
  }

  /**
   * @description method to retrieve selected adobe product ids
   *
   * @param {Array} allPackagedProducts - list of all packaged products
   * @param {Boolean} isUpdatesOnlyPackage - whether the package should contain only the updates or the already updated products as well
   * @returns {Object[]} Products to update
   */
  static getSelectedAdobeProductIds(allPackagedProducts, isUpdatesOnlyPackage) {
    return this.getProductsForUpdateV2(allPackagedProducts, isUpdatesOnlyPackage);
  }

  /**
   * @description Retrieves array of products for update
   * @param {Array} allPackagedProducts - list of all packaged products
   * @param {Object} isUpdatesOnlyPackage - updates only package
   *
   * @returns {Array} Array of products for update
   */
  static getProductsForUpdateV2(allPackagedProducts, isUpdatesOnlyPackage) {
    let updatedProductSet;

    // remove KCCC and invisible products
    const packagedProducts = allPackagedProducts.filter(packagedProductsFilter);

    // set of keys of original list of products
    const originalProductList = allPackagedProducts.map((product) => product.product_key);

    // seperate non-STI products
    const pointProducts = packagedProducts.filter((product) => !product.isSTI);

    // if no point product, add all products (STIs)
    if (pointProducts.length === 0) {
      updatedProductSet = extractLatestProductKeys(
        packagedProducts.map((product) => product.latestProductKey)
      );
    } else {
      // add latest versions of all point products and their latest STIs to updatedProductSet
      updatedProductSet = getUpdatedProductKeysSet(true, pointProducts);
    }
    if (!isUpdatesOnlyPackage) {
      // if its not update only package, return updatedProductSet as it contains all latest products
      // including those which were already up to date
      return [...updatedProductSet];
    }

    // if its updatesOnlyPackage, return updatedProductSet minus originalProductList
    const updatesOnlyKeys = [...updatedProductSet].filter(
      (updatedProduct) => ![...originalProductList].includes(updatedProduct)
    );

    // re-add all dependent STIs for outdated point products whose update is not
    // already packaged to make sure they didn't got removed in above diff
    return [...new Set([...updatesOnlyKeys, ...getUpdatedProductKeysSet(false, pointProducts)])];
  }

  /**
   * @description Method to post package creation request and based on post request's response ahow appropriate toast
   *
   * @param {Boolean} containsArchived - indicates if archived products selected
   * @param {Boolean} isUpdatesOnlyPackage - indicates if updates only package
   * @param {Array} packagedPlugins - list of packages plugins
   * @param {Object} packageCreateObj - main package create object
   * @param {String} workflow - workflow type
   */
  static async postPackageCreationRequest({
    containsArchived,
    isUpdatesOnlyPackage,
    packageCreateObj,
    packagedPlugins,
    workflow,
  }) {
    packageCreateObj.packageId = uuidv4();
    triggerIngestEvent(packageCreateObj, workflow);
    try {
      eventBus.emit(CREATE_PACKAGE.EVENTS.PACKAGE_REQUESTED);
      const response = await postPackageCustomizationRequest(cloneDeep({...packageCreateObj}), {
        user_specific_apis: true,
      });
      this.onPostPackageCustomizationRequestSuccess({
        callResult: response.data,
        // Below params will later be passed to triggerAnalytics call
        containsArchived,
        isUpdatesOnlyPackage,
        packageCreateObj,
        packagedPlugins,
        workflow,
      });
    } catch (error) {
      log.error('Create package API failure.', error);
      showError(translateString({id: 'packages.errors.allPackages.errorBuilding'}));
      this.closeCreatePackage();
    }
  }

  /**
   * @description Method called on successful api call
   *
   * @param {String} callResult - result on api call
   */
  static onPostPackageCustomizationRequestSuccess({callResult}) {
    if (callResult === 'SUCCESS') {
      // TODO: trigger analytics on successful package creation
      this.isFueFrlPackageCreation = false;
      this.isFueNamedPackageCreation = false;
      showSuccess(translateString({id: 'packages.alerts.createPackage.packageBuildingStart'}));
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- required to check page opened
      if (window.location.href.includes('packages/all-packages')) {
        eventBus.emit(CREATE_PACKAGE.EVENTS.NEW_PACKAGE_CREATED);
      } else {
        AdminPackages.markModelDirty();
        setTimeout(() => {
          navBus.navigate(
            generatePath(PATH_ALL_PACKAGES, {orgId: rootStore.organizationStore.activeOrgId})
          );
        }, 500);
        // need to emit event, in order to refresh page, to trigger refreshAdminPackages call of all packages tab
        // but event bus doesn't seem to be working here so, made a change there to do forced fetch when we navigate to all packages
        // eventBus.emit('PackagesNewPackageCreated', {});
      }
    } else if (callResult === 'ERROR_ORG_COUNTER_REACHED') {
      showWarning(
        translateString(
          {id: 'packages.errors.allPackages.errorOrgLimit'},
          {
            orgLimitCounter: rootStore.packagesUiConstantsStore.maxSimultaneousPackageBuildCount,
          }
        )
      );
    } else {
      showError(translateString({id: 'packages.errors.allPackages.errorBuilding'}));
    }
  }

  /**
   * @description Method returns value that indicates if template is Non CC
   * @param {Object} template - PackagesAdobeTemplateEntity object
   * @returns {Boolean} value that indicates if template is  Non CC
   */
  static isNonCCPackageTemplate(template) {
    return (
      template.isCaptivateTemplate ||
      template.isPhotoshopElementsTemplate ||
      template.isPremierElementsTemplate ||
      template.isPresenterTemplate ||
      template.isTechCommSuiteTemplate ||
      template.isRoboHelpTemplate ||
      template.isFramemakerTemplate ||
      template.isRoboHelpServerTemplate
    );
  }

  /**
   * @description Method returns value that indicates if template is Acrobat
   * @param {Object} template - PackagesAdobeTemplateEntity object
   * @returns {Boolean} value that indicates if template is  Acrobat
   */
  static isAcrobatOnlyTemplate() {
    return this.isAcrobatOnlyTpl;
  }
}

export default CreatePackageService;
/* eslint-enable max-lines -- this file requires more lines */
