import {
  feature,
  getAdminConsoleDisplayableContracts,
  hasOnlyTrialOrAllocationContracts,
} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import {isAuthUserContractAdmin} from 'core/contract/access/contractAccess';
import {
  canViewDirectContractBillingHistory,
  canViewOrganizationDetails,
  isAllowedToAddProducts,
  isContractAdmin,
  isOrgAdmin,
} from 'core/organizations/access/organizationAccess';
import {goToAccountRoot} from 'features/account/routing/navigation-callbacks/navigationCallbacks';
import {getContractForAccountPage} from 'features/account/utils/accountUtils';

/**
 * @description Method to determine whether the account overview page can be
 *     viewed. When there is only one contract that is an active direct contract,
 *     we hide account overview, otherwise it will be displayed.
 * @returns {boolean} True if that page can be viewed, false otherwise.
 */
function canViewAccountOverviewPage() {
  const displayableContracts = getAdminConsoleDisplayableContracts(
    rootStore.organizationStore.contractListIncludingInactive
  );

  return (
    displayableContracts.length > 1 ||
    !displayableContracts[0]?.isStatusActive() ||
    !(displayableContracts[0]?.isDirectContract() || displayableContracts[0]?.isIndirectContract())
  );
}

/**
 * @description returns true if change plan deep link flag is on, false otherwise
 */
function canUseChangePlanDeepLink() {
  const contract = getContractForAccountPage();
  return feature.isEnabled('temp_enable_change_plan_deep_link') && contract.canSwitch();
}

/**
 * @description returns true if self cancel deep link flag is on
 *
 * @returns {Boolean} - return with a boolean indicating whether to use deep link feature for self cancel
 */
function canUseSelfCancelDeepLink() {
  return feature.isEnabled('temp_self_cancel_deep_link');
}

/**
 * @description returns if the account page can be accessed for the current contract
 *    Account page is not accessible when the contract is enterprise or
 *    if the contract is trial or allocation contract.
 *
 * @returns {Boolean} - returns if the account page can be accessed or not
 */
function canAccessAccountPage(contractId = null) {
  const contractList = rootStore.organizationStore.contractList;
  const primaryContract = getContractForAccountPage(contractId);
  const isEnterpriseContract = primaryContract.isEnterpriseContract();

  const canAccess = feature.isEnabled('temp_multi_etla_pa_2881')
    ? // Don't show account page for enterprise contracts unless it's ETLA contract
      (!isEnterpriseContract || primaryContract.isBuyingProgramETLA()) &&
      !hasOnlyTrialOrAllocationContracts(contractList)
    : !isEnterpriseContract && !hasOnlyTrialOrAllocationContracts(contractList);

  return canAccess;
}

/**
 *  @description returns if the contract details page can be accessed for the current user.
 *    Contract details page is not accessible when the user is not a Contract Admin or System Admin.
 * @param {Object} args - The args object.
 * @param {Object} args.params - The parameters object.
 * @param {string} args.params.contractId - The ID of the contract.
 * @returns {boolean} - Returns true if the authenticated user has access to view the contract, otherwise false.
 */
function canViewContractDetails(args) {
  const contractId = args?.params?.contractId;
  return canAccessAccountPage(contractId) && (isOrgAdmin() || isAuthUserContractAdmin(contractId));
}

/**
 * @description returns if the current organization can view the account.
 * This differs form canViewAccountDetails as this is a check based on organization
 *
 * @returns {Boolean} - returns if the active organization can access the account tab
 */
function canViewAccounts() {
  if (feature.isEnabled('temp_contract_admin_role')) {
    return !rootStore.organizationStore.isActiveOrgDeveloper && (isOrgAdmin() || isContractAdmin());
  }
  return !rootStore.organizationStore.isActiveOrgDeveloper && isOrgAdmin();
}

/**
 * @description returns if the account tab can be accessed for this contract.
 *
 * @returns {Boolean} - returns true if account tab is accessible, false otherwise.
 */
function canViewAccountDetails() {
  return canAccessAccountPage();
}

/**
 * @description returns if the account tab child routes can be accessed for this contract.
 * @returns {Boolean} - returns true if account tab child routes is accessible,
 * else navigate to root account page and return false.
 */
function canAccessAccountPageChildRoutes(accessLoader) {
  return () => {
    if (typeof accessLoader === 'function' && accessLoader()) {
      return true;
    }
    goToAccountRoot();
    return false;
  };
}

/**
 * @description returns if the account tab add-products page can be accessed for this contract.
 * @returns {Boolean} - returns true if account tab add-products page is accessible, false otherwise.
 */
function canViewAddProductsOnAccount() {
  return canAccessAccountPage() && isAllowedToAddProducts();
}

/**
 * @description returns if the billing history tab can be accessed for this contract
 *
 * @returns {Boolean} - returns true if billing history is accessible, false otherwise.
 */
function canViewBillingHistory() {
  return canViewDirectContractBillingHistory();
}

/**
 * Checks if the the default contract for the acccount page can be managed or not.
 * @returns {Boolean} - returns true if the manage plan is accessible, returns false otherwise.
 */
function canManagePlan() {
  const contract = getContractForAccountPage();

  return (
    (feature.isEnabled('temp_self_cancel') ||
      feature.isEnabled('temp_self_cancel_trial_with_payment')) &&
    !contract.isInRenewalWindow() &&
    contract.isDirectContract() &&
    !contract.isDrContract &&
    (feature.isEnabled('temp_show_manage_plan') || isAllowedToAddProducts())
  );
}

/**
 * @description returns if the change plan modal can be accessed from the deep link
 */
function canViewChangePlanModalFromDeepLink(path) {
  return path.includes('/change-plan') && canUseChangePlanDeepLink();
}

/**
 * Checks if self cancel deeplink can is should allowed based on the given pathname.
 * @returns returns true of self cancel deeplink can be opened
 */
function canViewSelfCancelModalFromDeepLink(path) {
  const isCancelLicensesInUrl = path.includes('/cancel-licenses');
  return isCancelLicensesInUrl && canUseSelfCancelDeepLink();
}

/**
 * Access check for the Organization Details page.
 * @returns boolean if the access to organization details is allowed or not
 */
function canViewAccountOrganizationDetails() {
  return canViewOrganizationDetails();
}

/**
 * @description Check whether a contract can be opened in Account page
 * @param {Contract} contract Contract to open in Account page
 * @returns true if the contract can be opened in Account page, false otherwise
 */
function canViewContractInAccountPage(contract) {
  const isEligibleContract =
    contract.isDirectContract() ||
    contract.isIndirectContract() ||
    (feature.isEnabled('temp_multi_etla_pa_2881') && contract.isBuyingProgramETLA());
  return isEligibleContract && contract.isStatusActive();
}

export {
  canAccessAccountPage,
  canAccessAccountPageChildRoutes,
  canManagePlan,
  canUseChangePlanDeepLink,
  canUseSelfCancelDeepLink,
  canViewAccountDetails,
  canViewAccountOrganizationDetails,
  canViewAccountOverviewPage,
  canViewAccounts,
  canViewAddProductsOnAccount,
  canViewBillingHistory,
  canViewChangePlanModalFromDeepLink,
  canViewContractDetails,
  canViewContractInAccountPage,
  canViewSelfCancelModalFromDeepLink,
};
