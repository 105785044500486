import {EVENT_ACTION, dispatchUiEventAnalytics, feature} from '@admin-tribe/acsc';
import {Flex, Item, TabList, TabPanels, Tabs, View} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import CircleIcon from '@spectrum-icons/workflow/CircleFilled';
import React, {useEffect, useMemo, useRef, useState} from 'react';

import {
  AcceptFreeOffersHva,
  AddAdminsHva,
  AddProductsHva,
  AdobeExpressK12Hva,
  AssignEnterpriseLicensesHva,
  BookExpertSessionHva,
  ChangeContractOwnerHva,
  CreatePackagesHva,
  CreateUserGroupsHva,
  FinishAssigningLicensesHva,
  HVA_TYPE,
  OfferSwitchMigrationAssignLicensesHva,
  RosterSyncHva,
  SecurityContactsHva,
  SetPoliciesHva,
  SetUpFrlHva,
  SetUpIdentityHva,
  SetUpSdlHva,
  SetupSoidc,
  SophiaHvaCard,
  StartAssigningLicensesHva,
  TakeATourHva,
  UnpaidInvoicesHva,
  ViewReportsHva,
  VisitAdobeioHva,
  VisitUserGuideHva,
  useHvaCardList,
} from 'features/overview/hva-cards';
import OverviewPod from 'features/overview/shell/overview-pod/OverviewPod';

import HvaCard from '../hva-card/HvaCard';

import styles from './HvaSummarySection.pcss';

const SOPHIA_CARDS = [SophiaHvaCard];

const HVA_COL_SPAN = {
  base: 1,
  L: 2,
  M: 2,
};

/**
 * The HVA Summary Section component on the Overview page
 */
const HvaSummarySection = () => {
  // orders of VALUE_DISCOVERY_TASKS and SET_UP_TASKS should be aligned with
  // https://git.corp.adobe.com/admin-tribe/onesie/blob/master/src2/app/features/overview/hva-cards/hvaConstants.js
  let SET_UP_TASKS;
  const VALUE_DISCOVERY_TASKS = [
    AcceptFreeOffersHva,
    AddAdminsHva,
    CreatePackagesHva,
    ChangeContractOwnerHva,
    ViewReportsHva,
    SetPoliciesHva,
    BookExpertSessionHva,
    VisitAdobeioHva,
    CreateUserGroupsHva,
    VisitUserGuideHva,
  ];

  if (feature.isDisabled('temp_tno_enable_legacy_overview')) {
    SET_UP_TASKS = [
      SetupSoidc,
      RosterSyncHva,
      AdobeExpressK12Hva,
      TakeATourHva,
      UnpaidInvoicesHva,
      SetUpIdentityHva,
      SetUpSdlHva,
      OfferSwitchMigrationAssignLicensesHva,
      SetUpFrlHva,
      AssignEnterpriseLicensesHva,
      SecurityContactsHva,
    ];
  } else {
    SET_UP_TASKS = [
      SetupSoidc,
      RosterSyncHva,
      AdobeExpressK12Hva,
      TakeATourHva,
      AddProductsHva,
      UnpaidInvoicesHva,
      SetUpIdentityHva,
      SetUpSdlHva,
      OfferSwitchMigrationAssignLicensesHva,
      SetUpFrlHva,
      StartAssigningLicensesHva,
      FinishAssigningLicensesHva,
      AssignEnterpriseLicensesHva,
      SecurityContactsHva,
    ];
  }

  const [discoveryTasks, , removeValueDiscoveryTask, isLoadingDiscoveryTasks] =
    useHvaCardList(VALUE_DISCOVERY_TASKS);
  const [setUpTasks, , removeSetUpTask, isLoadingSetUpTasks] = useHvaCardList(SET_UP_TASKS);
  const [sophiaCards, , removeSophiaCard, isLoadingSophiaCards] = useHvaCardList(SOPHIA_CARDS);
  const [isRecentlyChangedHvaCardList, setIsRecentlyChangedHvaCardList] = useState(true);

  const [currentTab, setCurrentTab] = useState();

  const onSelectionChange = (cardId) => {
    if (isRecentlyChangedHvaCardList) {
      setCurrentTab(cardId);
    }
  };

  const dismissHvaCard = (card) => {
    setIsRecentlyChangedHvaCardList(false);
    switch (card.type) {
      case HVA_TYPE.SET_UP:
        removeSetUpTask(card);
        return;
      case HVA_TYPE.VALUE_DISCOVERY:
        removeValueDiscoveryTask(card);
        break;
      case HVA_TYPE.SOPHIA:
        removeSophiaCard(card);
        break;
      default:
        break;
    }
  };

  // ONESIE-43694: This must be a constant to allow for the css selector to work for the header of the carousel
  const titledSectionId = 'hva-banner-titled-section';

  const allCards = useMemo(
    () => [...sophiaCards, ...setUpTasks, ...discoveryTasks],
    [sophiaCards, setUpTasks, discoveryTasks]
  );

  const isLoadingCards = useMemo(
    () => isLoadingDiscoveryTasks || isLoadingSetUpTasks || isLoadingSophiaCards,
    [isLoadingDiscoveryTasks, isLoadingSetUpTasks, isLoadingSophiaCards]
  );

  /**
   * When a HVA card is dismissed, isRecentlyChangedHvaCardList will be set to false
   * to prevent setCurrentTab from being executed. However, when a HVA card is dismissed, allCards
   * was changed so setting isRecentlyChangedHvaCardList to true so that setCurrentTab can be called
   * when tab changing was made.
   */
  useEffect(() => {
    if (!isLoadingCards) {
      setIsRecentlyChangedHvaCardList(true);
    }
  }, [isLoadingCards, allCards]);

  const currentCard = useMemo(() => {
    switch (allCards.length) {
      case 0:
        // we don't render the HVA section if there are no cards
        return null;
      case 1:
        // there's only 1 task left
        return allCards[0];
      default:
        // there's a carousel, let the carousel state tell us what is being viewed
        return allCards.find(({id}) => id === currentTab) ?? allCards[0];
    }
  }, [currentTab, allCards]);

  const firstRender = useRef(true);

  const isAdobeExpressK12CardVisible =
    (feature?.isEnabled('temp_adobe_express_k12') &&
      firstRender.current &&
      currentCard?.id === 'hva-adobe-express-k12') ||
    allCards[0]?.id === 'hva-adobe-express-k12';

  // Dispatch analytics event when user sees the Adobe Express K12 HVA card
  useEffect(() => {
    if (isAdobeExpressK12CardVisible) {
      firstRender.current = false;
      dispatchUiEventAnalytics?.({
        eventAction: EVENT_ACTION.DISPLAY,
        eventName: 'displayAdobeExpressK12HvaCard',
      });
    }
  }, [allCards, currentCard, isAdobeExpressK12CardVisible]);

  // render blank card when loading
  if (isLoadingCards) {
    return (
      <OverviewPod columnSpan={HVA_COL_SPAN} data-testid="hva-summary-section" isLoading>
        <View
          backgroundColor="gray-50"
          borderColor="gray-200"
          borderRadius="medium"
          borderWidth="thin"
          height="size-1200"
          padding="size-75"
        />
      </OverviewPod>
    );
  }

  // don't render HVA section if there are no cards
  if (allCards.length === 0) {
    return null;
  }

  // if there is only one card left, don't render a carousel
  if (allCards.length === 1) {
    return (
      <OverviewPod columnSpan={HVA_COL_SPAN} data-testid="hva-summary-section">
        <TitledSection
          data-hva-card-id={currentCard?.id}
          dividerPlacement={DividerPlacement.NONE}
          headingLevel={2}
          title={currentCard?.sectionTitle}
        >
          <HvaCard card={currentCard} onDismiss={dismissHvaCard} />
        </TitledSection>
      </OverviewPod>
    );
  }

  // else render a carousel
  return (
    <OverviewPod columnSpan={HVA_COL_SPAN} data-testid="hva-summary-section">
      <View
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- carousel style
        UNSAFE_className={styles.hvatitlesection}
      >
        <Tabs
          aria-labelledby={titledSectionId}
          defaultSelectedKey={currentCard?.id}
          density="compact"
          isEmphasized
          isQuiet
          items={allCards}
          onSelectionChange={onSelectionChange}
        >
          <TitledSection
            data-hva-card-id={currentCard?.id}
            dividerPlacement={DividerPlacement.NONE}
            headingLevel={2}
            id={titledSectionId}
            title={currentCard?.sectionTitle}
          >
            <Flex justifyContent="end" marginTop="-32px">
              <TabList
                // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- carousel style
                UNSAFE_className={styles.tablist}
              >
                {({id, sectionTitle}) => (
                  <Item key={id} textValue={sectionTitle}>
                    {/* Tabs sets the size of this icon. */}
                    <CircleIcon aria-label={sectionTitle} color="positive" />
                  </Item>
                )}
              </TabList>
            </Flex>
            <TabPanels>
              {(card) => (
                <Item key={card.id}>
                  <HvaCard card={card} onDismiss={dismissHvaCard} />
                </Item>
              )}
            </TabPanels>
          </TitledSection>
        </Tabs>
      </View>
    </OverviewPod>
  );
};

HvaSummarySection.propTypes = {};

export default HvaSummarySection;
