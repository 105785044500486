/* eslint-disable id-length -- paths have long names */

/**
 * React paths for the Product section.
 */

// The base segment for /products
const PATH_PRODUCTS = '/:orgId/products';
const PATH_SEGMENT_ADD_PRODUCTS = 'add-products';
const PATH_ADD_PRODUCTS = `${PATH_PRODUCTS}/${PATH_SEGMENT_ADD_PRODUCTS}`;

const PATH_SEGMENT_ASSIGN_PRODUCTS = 'assign-products';
const PATH_ASSIGN_PRODUCTS = `${PATH_PRODUCTS}/${PATH_SEGMENT_ASSIGN_PRODUCTS}`;

// App integrations segments and paths
const PATH_SEGMENT_APP_INTEGRATIONS = 'application-integrations';
const PATH_SEGMENT_APP_INTEGRATIONS_POLICIES = 'policies';
const PATH_SEGMENT_APP_INTEGRATIONS_USER_ACCEPTED = 'user-accepted';

const PATH_PRODUCTS_APP_INTEGRATIONS = `${PATH_PRODUCTS}/${PATH_SEGMENT_APP_INTEGRATIONS}`;
const PATH_PRODUCTS_APP_INTEGRATIONS_POLICIES = `${PATH_PRODUCTS}/${PATH_SEGMENT_APP_INTEGRATIONS}/${PATH_SEGMENT_APP_INTEGRATIONS_POLICIES}`;
const PATH_PRODUCTS_APP_INTEGRATIONS_USER_ACCEPTED = `${PATH_PRODUCTS}/${PATH_SEGMENT_APP_INTEGRATIONS}/${PATH_SEGMENT_APP_INTEGRATIONS_USER_ACCEPTED}`;

const PATH_SEGMENT_PRODUCTS_APP_ADD_ONS = 'app-add-ons';
const PATH_PRODUCTS_APP_ADD_ONS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCTS_APP_ADD_ONS}`;

// Product access automation segments and paths
const PATH_SEGMENT_AUTO_ASSIGN = 'auto-assign';
const PATH_SEGMENT_PRODUCT_REQUESTS = 'requests';
const PATH_SEGMENT_PRODUCT_REQUEST_REVIEW = ':userId';

const PATH_PRODUCTS_AUTO_ASSIGN = `${PATH_PRODUCTS}/${PATH_SEGMENT_AUTO_ASSIGN}`;
const PATH_PRODUCTS_REQUESTS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_REQUESTS}`;
const PATH_PRODUCTS_REQUEST_REVIEW = `${PATH_PRODUCTS_REQUESTS}/${PATH_SEGMENT_PRODUCT_REQUEST_REVIEW}`;

const PATH_SEGMENT_PRODUCT_DETAILS = ':productId';

// Device-related (DBL) segments and paths
const PATH_SEGMENT_DEVICES = 'devices';
const PATH_SEGMENT_DEVICES_LEARN_MORE = 'learn-more';
const PATH_SEGMENT_DEVICES_DEACTIVATED = 'deactivated';
const PATH_SEGMENT_DEVICES_PAYMENT_NEEDED = 'payment-needed-devices';
const PATH_SEGMENT_PRODUCT_DETAILS_DEVICES = 'devices';

// Generic path segment constants for both Product and Profile
const PATH_SEGMENT_PERMISSIONS = 'permissions';

const PATH_PRODUCTS_DEVICES = `${PATH_PRODUCTS}/${PATH_SEGMENT_DEVICES}`;
const PATH_PRODUCTS_DEVICES_LEARN_MORE = `${PATH_PRODUCTS}/${PATH_SEGMENT_DEVICES}/${PATH_SEGMENT_DEVICES_LEARN_MORE}`;
const PATH_PRODUCTS_DEVICES_DEACTIVATED = `${PATH_PRODUCTS}/${PATH_SEGMENT_DEVICES}/${PATH_SEGMENT_DEVICES_DEACTIVATED}`;
const PATH_PRODUCT_DETAILS_DEVICES_PAYMENT_NEEDED = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_DEVICES_PAYMENT_NEEDED}`;
const PATH_PRODUCT_DETAILS_DEVICES = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_DETAILS_DEVICES}`;

// FRL (feature restricted license) segments and paths
const PATH_SEGMENT_FEATURE_RESTRICTED = 'feature-restricted';
const PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_FEATURE_RESTRICTED}`;
const PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED_PERMISSIONS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_FEATURE_RESTRICTED}/${PATH_SEGMENT_PERMISSIONS}`;

// Product details page segments and paths
const PATH_SEGMENT_PRODUCT_DETAILS_ADMINS = 'admins';
const PATH_SEGMENT_PRODUCT_DETAILS_PROFILES = 'profiles';
const PATH_SEGMENT_PRODUCT_DETAILS_USERS = 'users';
const PATH_SEGMENT_PRODUCT_DETAILS_TRANSACTIONS = 'transactions';
const PATH_SEGMENT_PRODUCT_DETAILS_ORG_DELEGATABLE_UNLIMITED = 'org-delegatable-unlimited';
const PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS = 'bulk-operations';
const PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS = 'bulk-operations/:jobId';
const PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT =
  'bulk-operations/:jobId/deficit';
const PATH_SEGMENT_PRODUCT_GROUP_DETAILS = 'groups/:groupId';

const PATH_PRODUCT_GROUP_DETAILS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_GROUP_DETAILS}`;
const PATH_PRODUCT_DETAILS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}`;
const PATH_PRODUCT_DETAILS_ADMINS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_DETAILS_ADMINS}`;
const PATH_PRODUCT_DETAILS_BULK_OPERATIONS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS}`;
const PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS}`;
const PATH_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT}`;

const PATH_PRODUCT_DETAILS_PROFILES = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_DETAILS_PROFILES}`;
const PATH_PRODUCT_DETAILS_USERS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_DETAILS_USERS}`;
const PATH_PRODUCT_DETAILS_TRANSACTIONS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_DETAILS_TRANSACTIONS}`;
const PATH_PRODUCT_DETAILS_ORG_DELEGATABLE_UNLIMITED = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_DETAILS_ORG_DELEGATABLE_UNLIMITED}`;

// Product profile details page segments and paths
const PATH_SEGMENT_PRODUCT_PROFILE_DETAILS = 'profiles/:profileId';
const PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_ADMINS = 'admins';
const PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_DEVELOPERS = 'developers';
const PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_DEVICES = 'devices';
const PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_INTEGRATIONS = 'integrations';
const PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_PERMISSIONS = 'permissions';
const PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_USERS = 'users';
const PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS = 'profiles/:profileId/bulk-operations';
const PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS =
  'profiles/:profileId/bulk-operations/:jobId';
const PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT =
  'profiles/:profileId/bulk-operations/:jobId/deficit';

const PATH_PRODUCT_PROFILE_DETAILS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS}`;
const PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS}`;
const PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS}`;
const PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT}`;
const PATH_PRODUCT_PROFILE_DETAILS_DEVICES = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_DEVICES}`;
const PATH_PRODUCT_PROFILE_DETAILS_PERMISSIONS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_PERMISSIONS}`;
const PATH_PRODUCT_PROFILE_DETAILS_ADMINS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_ADMINS}`;
const PATH_PRODUCT_PROFILE_DETAILS_DEVELOPERS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_DEVELOPERS}`;
const PATH_PRODUCT_PROFILE_DETAILS_INTEGRATIONS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_INTEGRATIONS}`;
const PATH_PRODUCT_PROFILE_DETAILS_USERS = `${PATH_PRODUCTS}/${PATH_SEGMENT_PRODUCT_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS}/${PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_USERS}`;

export {
  PATH_ADD_PRODUCTS,
  PATH_ASSIGN_PRODUCTS,
  PATH_PRODUCT_DETAILS,
  PATH_PRODUCT_DETAILS_ADMINS,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_PRODUCT_DETAILS_DEVICES,
  PATH_PRODUCT_DETAILS_DEVICES_PAYMENT_NEEDED,
  PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED,
  PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED_PERMISSIONS,
  PATH_PRODUCT_DETAILS_ORG_DELEGATABLE_UNLIMITED,
  PATH_PRODUCT_DETAILS_PROFILES,
  PATH_PRODUCT_DETAILS_TRANSACTIONS,
  PATH_PRODUCT_DETAILS_USERS,
  PATH_PRODUCT_GROUP_DETAILS,
  PATH_PRODUCT_PROFILE_DETAILS,
  PATH_PRODUCT_PROFILE_DETAILS_ADMINS,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_PRODUCT_PROFILE_DETAILS_DEVELOPERS,
  PATH_PRODUCT_PROFILE_DETAILS_DEVICES,
  PATH_PRODUCT_PROFILE_DETAILS_INTEGRATIONS,
  PATH_PRODUCT_PROFILE_DETAILS_PERMISSIONS,
  PATH_PRODUCT_PROFILE_DETAILS_USERS,
  PATH_PRODUCTS,
  PATH_PRODUCTS_APP_ADD_ONS,
  PATH_PRODUCTS_APP_INTEGRATIONS,
  PATH_PRODUCTS_APP_INTEGRATIONS_POLICIES,
  PATH_PRODUCTS_APP_INTEGRATIONS_USER_ACCEPTED,
  PATH_PRODUCTS_AUTO_ASSIGN,
  PATH_PRODUCTS_DEVICES,
  PATH_PRODUCTS_DEVICES_DEACTIVATED,
  PATH_PRODUCTS_DEVICES_LEARN_MORE,
  PATH_PRODUCTS_REQUEST_REVIEW,
  PATH_PRODUCTS_REQUESTS,
  PATH_SEGMENT_ADD_PRODUCTS,
  PATH_SEGMENT_ASSIGN_PRODUCTS,
  PATH_SEGMENT_APP_INTEGRATIONS,
  PATH_SEGMENT_APP_INTEGRATIONS_POLICIES,
  PATH_SEGMENT_APP_INTEGRATIONS_USER_ACCEPTED,
  PATH_SEGMENT_AUTO_ASSIGN,
  PATH_SEGMENT_DEVICES,
  PATH_SEGMENT_DEVICES_DEACTIVATED,
  PATH_SEGMENT_DEVICES_LEARN_MORE,
  PATH_SEGMENT_DEVICES_PAYMENT_NEEDED,
  PATH_SEGMENT_FEATURE_RESTRICTED,
  PATH_SEGMENT_PERMISSIONS,
  PATH_SEGMENT_PRODUCT_DETAILS,
  PATH_SEGMENT_PRODUCT_DETAILS_ADMINS,
  PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS,
  PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_SEGMENT_PRODUCT_DETAILS_DEVICES,
  PATH_SEGMENT_PRODUCT_DETAILS_ORG_DELEGATABLE_UNLIMITED,
  PATH_SEGMENT_PRODUCT_DETAILS_PROFILES,
  PATH_SEGMENT_PRODUCT_DETAILS_TRANSACTIONS,
  PATH_SEGMENT_PRODUCT_DETAILS_USERS,
  PATH_SEGMENT_PRODUCT_GROUP_DETAILS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_ADMINS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_DEVELOPERS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_DEVICES,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_INTEGRATIONS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_PERMISSIONS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_USERS,
  PATH_SEGMENT_PRODUCT_REQUEST_REVIEW,
  PATH_SEGMENT_PRODUCT_REQUESTS,
  PATH_SEGMENT_PRODUCTS_APP_ADD_ONS,
};

/* eslint-enable id-length -- paths have long names */
