/* eslint-disable max-lines -- This file requires more lines */
import {
  OrganizationUser,
  feature,
  getProductListWithLicenseGroupSummariesIfSafe,
  log,
  saveMemberProductRoles,
  toBinkyLicenseGroup,
  toBinkyProduct,
  toBinkyUserGroup,
  toPandoraLicenseGroup,
  toPandoraProduct,
  toPandoraUserGroup,
} from '@admin-tribe/acsc';
import binkyUI from '@admin-tribe/acsc-ui';
import {Content, Flex, Heading, InlineAlert, Text, View} from '@adobe/react-spectrum';
import {
  AssignmentAddMoreActionButtonContextProvider,
  AssignmentLicenseLimitProvider,
  ITEM_TYPE,
} from '@pandora/react-assignment-modal';
import {
  AssignmentModalSection,
  AssignmentModalSectionContentModel,
  AssignmentSectionContext,
} from '@pandora/react-assignment-section';
import {useContentEntry} from '@pandora/react-content-provider';
import {ProductRoleContext, ProductRoleMemberContext} from '@pandora/react-mini-cards';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import useAddProductModal from 'common/hooks/useAddProductModal';
import rootStore from 'core/RootStore';
import {isAllowedToAddProducts} from 'core/organizations/access/organizationAccess';
import {addTagsToProducts} from 'core/products/utils/productTagUtils';
import {getContractDisplayNames} from 'core/products/utils/productUtils';
import chatProvider from 'core/services/chat/chatProvider';
import {ADD_PRODUCT_STEPS} from 'features/products/components/add-product-modal-wrapper/AddProduct.constants';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';
import {dispatchUserModalSuccessAnalytics} from 'features/users/services/user-modals/userModalAnalyticsUtils';

import styles from './AssignmentModalBase.pcss';

const {ModalContainer, ModalContent, ModalDescription, ModalDialog, ModalHeading, ModalTagHeader} =
  binkyUI.common.components.modal;
const {getOrganizationUserErrorMessage} =
  binkyUI.organization.organizationUser.organizationUserDisplayUtils;
const {toPandoraProductTargets, toPandoraUserGroupTargets} =
  binkyUI.common.components.assignmentSectionUtils;
const {TARGET_TYPE} = binkyUI.common.components.ASSIGNMENT_MENU_CONSTANTS;

/* eslint-disable max-statements -- large file*/
// eslint-disable-next-line complexity -- large file
const AssignmentModalBase = ({
  warningMessage,
  analyticsContextFunc,
  assignedItemLocId,
  assignItemsToUser,
  canEditProductRoles = false,
  description,
  hasUnsavedChanges,
  header,
  id,
  isOpen,
  onCancel,
  onItemAssignment,
  onSuccess,
  orgId,
  onProductListResolve,
  processDefaultItemsNew,
  processDisabledItems,
  TagHeaderIconComponent,
  tagHeaderText,
  targets,
  user,
  displayAddMoreButton = false,
}) => {
  const intl = useIntl();
  const [products, setProducts] = useState([]);
  const [pandoraProducts, setPandoraProducts] = useState([]);
  const [isLoadingUserAndProducts, setIsLoadingUserAndProducts] = useState(false);
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [pandoraItemsToDisable, setPandoraItemsToDisable] = useState([]);
  const [pandoraItemsToPreselect, setPandoraItemsToPreselect] = useState(undefined);
  const [assignedItemCount, setAssignedItemCount] = useState(0);
  const [modalError, setModalError] = useState(null);
  const binkyItemMap = useRef({
    [TARGET_TYPE.PRODUCTS]: [],
    [TARGET_TYPE.PRODUCT_PROFILES]: [],
    [TARGET_TYPE.USER_GROUPS]: [],
  });
  const assignedItems = useRef({});
  const [assignedProductRoles, setAssignedProductRoles] = useState();
  const content = useContentEntry(AssignmentModalSectionContentModel);
  const productTargets = toPandoraProductTargets(targets);
  const userGroupTargets = toPandoraUserGroupTargets(targets);
  const userGroupFilterCriteria = 'externallyManaged';
  const shouldDisplayAddMoreButton = displayAddMoreButton && isAllowedToAddProducts();

  const showModalError = useCallback(() => {
    setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
  }, [intl]);

  const onCta = async () => {
    let isSuccess = false;
    try {
      const initialUserState = cloneDeep(user);
      setIsSavingUser(true);
      assignItemsToUser(assignedItems.current, assignedProductRoles);
      await user.save();

      if (canEditProductRoles) {
        await saveMemberProductRoles([user], {editProductRoles: true, orgId});
      }

      onSuccess();
      isSuccess = true;
      dispatchUserModalSuccessAnalytics({
        committedItems: [user],
        eventName: 'AssignmentModalBase',
        initialItems: [initialUserState],
        orgId,
      });
    } catch (error) {
      log.error('Failed to save user. Error: ', error);
      setModalError(getOrganizationUserErrorMessage(intl, error));
    } finally {
      setIsSavingUser(false);
    }
    return isSuccess;
  };

  const onItemAssignmentInternal = useCallback(
    (items) => {
      setAssignedItemCount(items.length);
      assignedItems.current = onItemAssignment(items);
    },
    [onItemAssignment]
  );

  const pandoraOnProductRoleChange = useCallback((pandoraRoles) => {
    const binkyRoles = pandoraRoles.map((pandoraRole) => ({
      // We must not forget to convert the Pandora models to the Binky equivalents here.
      // Product should be the only such model in the OnProductRoleChangeProps type.
      ...pandoraRole,
      product: toBinkyProduct(pandoraRole.product),
    }));

    setAssignedProductRoles(binkyRoles);
  }, []);

  const productAssignmentModalOnChange = useCallback(
    (itemMap) => {
      const combinedItemMap = {
        ...binkyItemMap.current,
        [TARGET_TYPE.PRODUCTS]: itemMap[ITEM_TYPE.PRODUCTS].map((product) =>
          toBinkyProduct(product)
        ),
        [TARGET_TYPE.PRODUCT_PROFILES]: itemMap[ITEM_TYPE.PRODUCT_PROFILES].map((profile) =>
          toBinkyLicenseGroup(profile)
        ),
      };
      binkyItemMap.current = combinedItemMap;
      onItemAssignmentInternal([
        ...combinedItemMap[TARGET_TYPE.PRODUCTS],
        ...combinedItemMap[TARGET_TYPE.PRODUCT_PROFILES],
        ...combinedItemMap[TARGET_TYPE.USER_GROUPS],
      ]);
    },
    [onItemAssignmentInternal]
  );

  const userGroupAssignmentModalOnChange = useCallback(
    (itemMap) => {
      const combinedItemMap = {
        ...binkyItemMap.current,
        [TARGET_TYPE.USER_GROUPS]: itemMap[ITEM_TYPE.USER_GROUPS].map((userGroup) =>
          toBinkyUserGroup(userGroup)
        ),
      };
      binkyItemMap.current = combinedItemMap;
      onItemAssignmentInternal([
        ...combinedItemMap[TARGET_TYPE.PRODUCTS],
        ...combinedItemMap[TARGET_TYPE.PRODUCT_PROFILES],
        ...combinedItemMap[TARGET_TYPE.USER_GROUPS],
      ]);
    },
    [onItemAssignmentInternal]
  );

  // Generate the AssignmentModal's "preselectedItems" and "disabledItems" from the given parameters
  useEffect(() => {
    const transformToPandoraLicenseGroup = (licenseGroup) => {
      if (feature.isEnabled('temp_add_contract_display_name')) {
        // Adding contractDisplayNames in product of license group if feature flag is enabled
        const contractDisplayNames = getContractDisplayNames(licenseGroup.product?.contractIds);
        const updatedLicenseGroup = {
          ...licenseGroup,
          product: {...licenseGroup.product, contractDisplayNames},
        };
        return toPandoraLicenseGroup(updatedLicenseGroup);
      }
      return toPandoraLicenseGroup(licenseGroup);
    };
    const preselectedItems = processDefaultItemsNew();
    if (preselectedItems) {
      setPandoraItemsToPreselect({
        // "Products" are just ID strings in this format so they don't need conversion.
        [TARGET_TYPE.PRODUCTS]: preselectedItems[TARGET_TYPE.PRODUCTS],
        [TARGET_TYPE.PRODUCT_PROFILES]: preselectedItems[TARGET_TYPE.PRODUCT_PROFILES]?.map(
          (licenseGroup) => transformToPandoraLicenseGroup(licenseGroup)
        ),
        [TARGET_TYPE.USER_GROUPS]: preselectedItems[TARGET_TYPE.USER_GROUPS]?.map((userGroup) =>
          toPandoraUserGroup(userGroup)
        ),
      });
    }

    const disabledItems = processDisabledItems?.() ?? {};
    setPandoraItemsToDisable({
      // "Products" are just ID strings in this format so they don't need conversion.
      [TARGET_TYPE.PRODUCTS]: disabledItems[TARGET_TYPE.PRODUCTS],
      [TARGET_TYPE.PRODUCT_PROFILES]: disabledItems[TARGET_TYPE.PRODUCT_PROFILES]?.map(
        (licenseGroup) => toPandoraLicenseGroup(licenseGroup)
      ),
      [TARGET_TYPE.USER_GROUPS]: disabledItems[TARGET_TYPE.USER_GROUPS]?.map((userGroup) =>
        toPandoraUserGroup(userGroup)
      ),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once products changes
  }, [products]);

  // Update the pandora-products only when the binky products change.
  useEffect(() => {
    const transformToPandoraProduct = (product) => {
      if (feature.isEnabled('temp_add_contract_display_name')) {
        // Adding contractDisplayNames in product if feature flag is enabled
        const contractDisplayNames = getContractDisplayNames(product.contractIds);
        const updatedProduct = {...product, contractDisplayNames};
        return toPandoraProduct(updatedProduct);
      }
      return toPandoraProduct(product);
    };
    if (products) {
      setPandoraProducts(products.map((product) => transformToPandoraProduct(product)));
    } else {
      setPandoraProducts([]);
    }
  }, [products]);

  // eslint-disable @admin-tribe/admin-tribe/comment-side-effects -- zakn@ to update
  useEffect(() => {
    let mounted = true;

    if (isOpen) {
      const fetchProducts = async () => {
        setIsLoadingUserAndProducts(true);
        try {
          const userRefreshPromise = user.refresh();
          let productList;

          if (targets.includes(TARGET_TYPE.PRODUCTS)) {
            productList = await getProductListWithLicenseGroupSummariesIfSafe(orgId);
          }

          await userRefreshPromise;

          if (feature.isEnabled('temp_b2b_free_provisioning')) {
            // Remove b2b free offer from products
            user.products = user.products?.filter(
              (product) =>
                !rootStore.organizationStore.productList.freeItems.find(
                  (freeItem) => freeItem.id === product.id
                )
            );
            user.savedState.products = cloneDeep(user.products);
          }

          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- cawright@ to update
          // istanbul ignore else
          if (mounted && productList) {
            const filteredProductList = onProductListResolve?.(productList);
            addTagsToProducts(intl, productList, {filteredProducts: filteredProductList});
            setProducts(filteredProductList || productList.items);
          }
        } catch (error) {
          log.error('Failed to get user and products. Error: ', error);

          if (mounted) {
            showModalError();
          }
        }

        if (mounted) {
          setIsLoadingUserAndProducts(false);
        }
      };

      fetchProducts();
    } else {
      setModalError(null);
      setIsLoadingUserAndProducts(false);
      setProducts(null);
      setAssignedItemCount(0);
      assignedItems.current = {};
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on isOpen change
  }, [isOpen]);

  const hasUnsavedProductRoles = canEditProductRoles && assignedProductRoles?.length > 0;

  const pandoraAssignmentSectionValue = useMemo(
    () => ({
      itemsToDisable: pandoraItemsToDisable,
      itemsToPreselect: pandoraItemsToPreselect,
      products: pandoraProducts,
      setItemsToPreselect: setPandoraItemsToPreselect,
    }),
    [pandoraItemsToDisable, pandoraItemsToPreselect, pandoraProducts]
  );

  const productRoleValue = useMemo(() => ({onError: showModalError}), [showModalError]);
  const productRoleMemberValue = useMemo(
    () => ({member: canEditProductRoles ? user : undefined}),
    [canEditProductRoles, user]
  );

  const {closeAddProductModal, openAddProducts, showAddProductModal} = useAddProductModal({
    eventName: 'EditUserAddProducts',
  });

  const [addProductCartItems, setAddProductCartItems] = React.useState(null);
  const onAddMoreProduct = useCallback(
    (product) => {
      setAddProductCartItems([{offerId: product.offerId, quantity: 1}]);
      openAddProducts();
    },
    [openAddProducts]
  );
  const onAddProductModalOnClose = () => {
    setAddProductCartItems(null);
    closeAddProductModal();
  };

  // https://jira.corp.adobe.com/browse/ONESIE-43814
  // This is an experimental implementation of the add more button for products with no licenses remaining.
  const assignmentAddMoreActionButtonContext = {onAddMoreProduct};

  const renderAssignmentSectionsAddLicenses = () => (
    <AssignmentAddMoreActionButtonContextProvider value={assignmentAddMoreActionButtonContext}>
      <AssignmentLicenseLimitProvider value={{products: pandoraProducts}}>
        <AssignmentSectionContext.Provider
          // We cannot convert Binky Products to Pandora Products here, this gets run
          // every render, and the conversion is too heavy-weight.
          value={pandoraAssignmentSectionValue}
        >
          <ProductRoleContext.Provider value={productRoleValue}>
            <ProductRoleMemberContext.Provider value={productRoleMemberValue}>
              <Flex direction="column" gap="size-200" marginTop="size-200">
                {productTargets.length > 0 && (
                  <AssignmentModalSection
                    content={content}
                    onChange={productAssignmentModalOnChange}
                    onProductRolesChange={(roles) => {
                      pandoraOnProductRoleChange(roles);
                    }}
                    orgId={orgId}
                    showButtonLabel
                    targets={productTargets}
                  />
                )}
                {userGroupTargets.length > 0 && (
                  <AssignmentModalSection
                    content={content}
                    filterCriteria={
                      feature.isEnabled('temp_bug_fix_ad_sync_group_assignment') &&
                      userGroupFilterCriteria
                    }
                    onChange={userGroupAssignmentModalOnChange}
                    orgId={orgId}
                    showButtonLabel
                    targets={userGroupTargets}
                  />
                )}
              </Flex>
            </ProductRoleMemberContext.Provider>
          </ProductRoleContext.Provider>
        </AssignmentSectionContext.Provider>
      </AssignmentLicenseLimitProvider>
    </AssignmentAddMoreActionButtonContextProvider>
  );

  const renderAssignmentSections = () => (
    <AssignmentLicenseLimitProvider value={{products: pandoraProducts}}>
      <AssignmentSectionContext.Provider
        // We cannot convert Binky Products to Pandora Products here, this gets run
        // every render, and the conversion is too heavy-weight.
        value={pandoraAssignmentSectionValue}
      >
        <ProductRoleContext.Provider value={productRoleValue}>
          <ProductRoleMemberContext.Provider value={productRoleMemberValue}>
            <Flex direction="column" gap="size-200" marginTop="size-200">
              {productTargets.length > 0 && (
                <AssignmentModalSection
                  content={content}
                  onChange={productAssignmentModalOnChange}
                  onProductRolesChange={(roles) => {
                    pandoraOnProductRoleChange(roles);
                  }}
                  orgId={orgId}
                  showButtonLabel
                  targets={productTargets}
                />
              )}
              {userGroupTargets.length > 0 && (
                <AssignmentModalSection
                  content={content}
                  filterCriteria={
                    feature.isEnabled('temp_bug_fix_ad_sync_group_assignment') &&
                    userGroupFilterCriteria
                  }
                  onChange={userGroupAssignmentModalOnChange}
                  orgId={orgId}
                  showButtonLabel
                  targets={userGroupTargets}
                />
              )}
            </Flex>
          </ProductRoleMemberContext.Provider>
        </ProductRoleContext.Provider>
      </AssignmentSectionContext.Provider>
    </AssignmentLicenseLimitProvider>
  );

  return (
    <>
      <ModalContainer>
        {isOpen && (
          <ModalDialog
            analyticsContextFunc={analyticsContextFunc}
            cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
            ctaLabel={intl.formatMessage({id: 'common.modal.buttons.save'})}
            ctaToastGenerator={() =>
              intl.formatMessage({id: 'common.toast.modal.usersUpdated'}, {userCount: 1})
            }
            errorMessage={modalError}
            heightVariant="static"
            id={id}
            isCtaDisabled={
              isSavingUser ||
              isLoadingUserAndProducts ||
              !(
                hasUnsavedChanges(assignedItems.current, assignedProductRoles) ||
                hasUnsavedProductRoles
              )
            }
            isLoading={isSavingUser || isLoadingUserAndProducts}
            onCancel={onCancel}
            onCta={onCta}
          >
            <ModalHeading>{header}</ModalHeading>
            <ModalTagHeader IconComponent={TagHeaderIconComponent}>{tagHeaderText}</ModalTagHeader>
            <ModalDescription data-testid="description">
              <Text>{description}</Text>
            </ModalDescription>
            <ModalContent>
              <Heading
                data-testid="assigned-item-count"
                level={3}
                marginBottom="size-150"
                marginTop="size-50"
                // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- required to set classes
                UNSAFE_className={styles.heading}
              >
                {intl.formatMessage({id: assignedItemLocId}, {count: assignedItemCount})}
              </Heading>
              {warningMessage && (
                <InlineAlert variant="negative" width="100%">
                  <Heading>
                    {intl.formatMessage({
                      id: 'common.editProductsAndUserGroupsModal.productIssueWarningAria',
                    })}
                  </Heading>
                  <Content>{warningMessage}</Content>
                </InlineAlert>
              )}
              {shouldDisplayAddMoreButton ? (
                <View marginBottom="size-200">{renderAssignmentSectionsAddLicenses()}</View>
              ) : (
                <View marginBottom="size-200">{renderAssignmentSections()}</View>
              )}
            </ModalContent>
          </ModalDialog>
        )}
      </ModalContainer>

      {showAddProductModal && (
        <AddProductModalWrapper
          chat={chatProvider}
          items={addProductCartItems}
          onClose={onAddProductModalOnClose}
          step={ADD_PRODUCT_STEPS.REVIEW_ORDER}
        />
      )}
    </>
  );
};
/* eslint-enable max-statements -- large file*/

AssignmentModalBase.propTypes = {
  // Analytics context that needs to be sent when the modal is opened
  analyticsContextFunc: PropTypes.func,
  // The loc string id for `Assigned {itemName}` label
  assignedItemLocId: PropTypes.string.isRequired,
  // Callback to apply the changes to the user before user.save
  // @param {Object} assignedItems - The object of assigned items returned from onItemAssignment
  assignItemsToUser: PropTypes.func.isRequired,
  // Defaults to false.
  canEditProductRoles: PropTypes.bool,
  description: PropTypes.node.isRequired,
  // Enables the "Add Licenses" button for products in the modal. Defaults to false.
  // For the button to be displayed, the user must have the permission to add products and the product must have no licenses remaining.
  // @param {Boolean} displayAddMoreButton - Toggle to display the "Add Licenses" button
  displayAddMoreButton: PropTypes.bool,
  // Callback to check whether changes have been made
  // @param {Object} assignedItems - The object of assigned items returned from onItemAssignment
  hasUnsavedChanges: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,

  onCancel: PropTypes.func.isRequired,
  // Callback when an item is selected in the menu.
  // @param {Array<Object>} - selectedItems Array of all selected items
  // @returns {Object} mapped object for each TARGET_TYPE, example below:
  // {
  //     [TARGET_TYPE.PRODUCT_PROFILES]: [new LicenseGroup(), ...],
  //     [TARGET_TYPE.PRODUCTS]: [new Product(), ...],
  // }
  onItemAssignment: PropTypes.func.isRequired,
  // Optional callback when ProductList is resolved, to do any filtering
  // @param {ProductList} - the resolved ProductList
  // @returns {Array<Product>} - the filtered productList items
  onProductListResolve: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  // Function to map user's roles to itemsToPreselect for AssignmentModalContext.
  // Should only be defined once ready, as AssignmentModalSection will use the first
  // non-undefined value as the initial state and ignore all other updates.
  // @returns {Object|undefined} mapped object for AssignmentModalContext, example below:
  // {
  //     [TARGET_TYPE.USER_GROUPS]: [new UserGroup(), ...],
  //     [TARGET_TYPE.PRODUCT_PROFILES]: [new LicenseGroup(), ...],
  //     [TARGET_TYPE.PRODUCTS]: ['productId1', 'productId2'],
  // }
  processDefaultItemsNew: PropTypes.func.isRequired,
  processDisabledItems: PropTypes.func,
  TagHeaderIconComponent: PropTypes.node.isRequired,

  tagHeaderText: PropTypes.string,
  // Array of targets for the AssignmentMenu
  targets: PropTypes.arrayOf(PropTypes.string),
  // will be an object if passed in as a prop of type OrgUser (from src1)
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(OrganizationUser)]),
  warningMessage: PropTypes.string,
};

export default AssignmentModalBase;
/* eslint-enable max-lines -- This file requires more lines */
