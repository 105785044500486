import {feature} from '@admin-tribe/acsc';
import React from 'react';
import {Navigate, generatePath} from 'react-router-dom';

import {createRoutesForTabs} from 'common/utils/routerUtils';
import rootStore from 'core/RootStore';
import {
  canViewPackages,
  canViewServers,
  canViewTemplates,
} from 'core/packages/access/packagesAccess';
import {buildAccessCheckLoader} from 'core/router/loaders';
import PreferencesPage from 'features/packages/components/preferences-page/PreferencesPage';
import AllPackagesPage from 'features/packages/pages/all-packages/AllPackagesPage';
import PregeneratedPackagesPage from 'features/packages/pages/pregenerated-packages/PregeneratedPackagesPage';
import ServersPage from 'features/packages/pages/servers-page/ServersPage';
import TemplatesPage from 'features/packages/pages/templates-page/TemplatesPage';
import ToolsPage from 'features/packages/pages/tools-page/ToolsPage';
import PackagesWorkspace from 'features/packages/workspace/PackagesWorkspace';

import {
  PATH_ALL_PACKAGES,
  PATH_PACKAGES_ROOT,
  PATH_PREGENERATED_PACKAGES_TEMPLATES,
  PATH_SINGLE_APPS,
  PATH_TEMPLATES,
} from './packagesPaths';

const allPackages = () =>
  generatePath(PATH_ALL_PACKAGES, {orgId: rootStore.organizationStore.activeOrgId});

const templatesPregeneratedPath = () =>
  generatePath(PATH_PREGENERATED_PACKAGES_TEMPLATES, {
    orgId: rootStore.organizationStore.activeOrgId,
  });

function getAdobeTemplatesRoute() {
  // in case ff is on and user visits adobe-templates on browser, they  must be redirected to new templates subpage experience
  if (feature.isEnabled('temp_packages_pregenerated_page')) {
    return {
      element: <Navigate to={templatesPregeneratedPath()} />,
      loader: buildAccessCheckLoader(canViewTemplates),
      path: 'adobe-templates',
    };
  }
  return {
    element: <TemplatesPage />,
    loader: buildAccessCheckLoader(canViewTemplates),
    path: 'adobe-templates',
  };
}

const tabRoutes = [{path: PATH_SINGLE_APPS}, {path: PATH_TEMPLATES}];

/** Get pre generated package related routes. Everything under `/:orgId/packages/pregenerated-packages`. */
function getPregeneratedPackagesRoute() {
  if (feature.isEnabled('temp_packages_pregenerated_page')) {
    const pregeneratedPackagesRoute = {
      children: [
        ...createRoutesForTabs({
          element: <PregeneratedPackagesPage />,
          routes: tabRoutes,
        }),
      ],
      path: 'pregenerated-packages',
    };
    if (feature.isEnabled('temp_packages_pregenerated_page_fixes')) {
      return [
        {
          ...pregeneratedPackagesRoute,
          loader: buildAccessCheckLoader(canViewTemplates),
        },
      ];
    }
    return [pregeneratedPackagesRoute];
  }
  return [];
}

/** Gathers all of the packages routes */
function packagesRoutes() {
  return [
    {
      children: [
        {
          element: <Navigate to={allPackages()} />,
          index: true,
        },
        getAdobeTemplatesRoute(),
        ...getPregeneratedPackagesRoute(),
        {
          element: <AllPackagesPage />,
          path: 'all-packages',
        },
        {
          element: <PreferencesPage />,
          path: 'preferences',
        },
        {
          element: <ToolsPage />,
          path: 'tools',
        },
        {
          element: <ServersPage />,
          loader: buildAccessCheckLoader(canViewServers),
          path: 'servers',
        },
      ],
      element: <PackagesWorkspace />,
      loader: buildAccessCheckLoader(canViewPackages),
      path: PATH_PACKAGES_ROOT,
    },
  ];
}

export default packagesRoutes;
