import {CONTRACT_STATUS, Locale, feature} from '@admin-tribe/acsc';
import {getContractDisplayName} from '@admin-tribe/acsc-ui';
import {SORT_ORDER} from '@pandora/react-table-section';

import {canViewContractInAccountPage} from 'core/account/access/accountAccess';
import {canDisplayContract} from 'core/contract/access/contractAccess';
import {getAccountPaths} from 'features/account/utils/accountUtils';

import {CONTRACT_TABLE_DATA_KEYS} from './OverviewConstants';
/**
 * @description Filter contract list to display
 * @param {ContractList} contractList ContractList instance, preferably with:
 *   ContractList.get({
 *     includeInactiveContracts: true,
 *     ...
 *   });
 * @returns {Contract[]} New array of displayable contracts, or an empty array
 * if contractList doesn't exist yet (during initial load)
 */
function filterContractList(contractList = {}) {
  return contractList.items
    ? contractList.items.filter((contract) => canDisplayContract(contract))
    : [];
}

/**
 * @description Return the appropriate contract date for a contract
 * @param {Contract} contract Contract instance
 * @returns {Date} Either contract anniversary date or end date
 */
function getContractDate(contract) {
  if (contract.isIndirectContract() || contract.isDirectContract()) {
    return contract.getAnniversaryDate();
  }

  // This may be undefined.
  return contract.getEndDate();
}

/**
 * @description Get list of products associated with a contract
 * @param {Contract} contract Contract instance
 * @param {Object} [productOptions] Options for products
 * @param {Boolean} [productOptions.allProducts] products may include
 * expired and inactive products (see useOverviewData.js), set this option to true
 * to include expired and inactive products for generating
 * ContractTableRowData[CONTRACT_TABLE_DATA_KEYS.PRODUCTS]
 * @returns {Product[]} A subset of Product list from rootStore
 */
function getContractProductsList({contract, productOptions = {}, products}) {
  return products.filter((product) => {
    // Purposely call "...NonActive..." because it covers both EXPIRED and INACTIVE license statuses
    const allowNonActiveProduct = productOptions.allProducts || contract.isStatusExpired();
    if (!allowNonActiveProduct && !product.isLicenseStatusActive()) {
      return false;
    }

    return product.hasContractId(contract.id);
  });
}

/**
 * @description Get applicable tags for a contract
 * @param {Contract} contract Contract instance
 * @param {Object} intl Result of react-intl useIntl()
 * @returns {Object[]} Zero or more tags
 */
function getContractTags({contract, intl}) {
  const tags = [];
  if (contract.isModelAllocation()) {
    tags.push({
      id: `${contract.id}-tag-allocation`,
      name: intl.formatMessage({id: 'account.overview.table.tags.allocation'}),
    });
  }

  if (feature.isEnabled('temp_multi_etla_pa_2881') ? contract.isModelTrial() : contract.isTrial()) {
    tags.push({
      id: `${contract.id}-tag-trial`,
      name: intl.formatMessage({id: 'account.overview.table.tags.trial'}),
    });
  }

  return tags;
}

/**
 * @description Get displayable contract type
 * @param {Contract} contract Contract instance
 * @param {Object} intl Result of react-intl useIntl()
 * @returns {String} Display contract type
 */
function getContractType({contract, intl}) {
  // Use short display name except for Digital Experience.
  const abbreviate =
    !contract.isEnterpriseContract() || !contract.isBuyingProgramEnterpriseProduct();

  return getContractDisplayName(intl, contract, {abbreviate});
}

/**
 * @description Helper to get the href to navigate to the contract details page.
 * @param {Contract} contract - Contract whose id will be used for generating
 *    the href.
 * @returns {Href} Href to navigate to the contract details page or undefined if
 *    there's no route.
 */
function getHrefForContractDetails(contract) {
  const canViewInAccountPage = feature.isEnabled('temp_pa_6055_account_link')
    ? canViewContractInAccountPage(contract)
    : contract.isIndirectContract() && !contract.isBuyingProgramVIPMP();

  if (canViewInAccountPage) {
    return getAccountPaths().accountContractDetails(contract.id);
  }
  return undefined;
}

/**
 * @description Sort a list of ContractTableRowData
 * @param {ContractTableRowData[]} rows List of ContractTableRowData to be sorted
 * @param {String} id Data key to sort on, see CONTRACT_TABLE_DATA_KEYS
 * @param {String} sortedOrder One of SORT_ORDER values
 * @returns {ContractTableRowData[]} Sorted row in a new list instance ('rows' is unchanged)
 */
function sortRows(rows, {id, sortedOrder}) {
  const language = Locale.get().activeLanguageBCP47Code;

  return [...rows].sort((a, b) => compareRows(a, b));

  ////////

  function compareRows(a, b) {
    const directionMultiplier = sortedOrder === SORT_ORDER.ASC ? 1 : -1;

    let result;

    if (id === CONTRACT_TABLE_DATA_KEYS.DATE) {
      const valueA = a[id] || 0;
      const valueB = b[id] || 0;

      if (valueA === valueB) {
        result = 0;
      } else {
        result = valueA < valueB ? -1 : 1;
      }
    } else {
      const valueA = a[id]?.toLocaleLowerCase() || '';
      const valueB = b[id]?.toLocaleLowerCase() || '';
      result = valueA.localeCompare(valueB, language);
    }

    return result * directionMultiplier;
  }
}

/**
 * @description Split an array of Contracts into active contracts and inactive contracts
 * @param {Contract[]} contracts Array of contracts.
 * @returns {Object} An Object with 2 keys:
 *   {
 *     activeContracts: list of active Contract instances
 *     inactiveContracts: list of inactive Contract instances
 *   }
 */
function splitContracts(contracts) {
  const activeContracts = contracts.filter(
    (contract) => contract.status !== CONTRACT_STATUS.INACTIVE
  );
  const inactiveContracts = contracts.filter(
    (contract) => contract.status === CONTRACT_STATUS.INACTIVE
  );

  return {activeContracts, inactiveContracts};
}

export {
  filterContractList,
  getContractDate,
  getContractTags,
  getContractType,
  getContractProductsList,
  getHrefForContractDetails,
  sortRows,
  splitContracts,
};
