import {feature} from '@admin-tribe/acsc';
import {
  Button,
  Content,
  ContextualHelp,
  DialogContainer,
  Flex,
  Heading,
  Item,
  Menu,
  MenuTrigger,
  Text,
  View,
} from '@adobe/react-spectrum';
import {Device} from '@pandora/react-data-model-device';
import {DeviceAction} from '@pandora/react-data-source-daco';
import {DEVICE_ACTIVATION_STATUS} from '@pandora/react-data-source-device-activation';
import {GoUrl} from '@pandora/react-go-url';
import {useTableSectionContext} from '@pandora/react-table-section';
import {error as showError} from '@react/react-spectrum/Toast';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import {useDeviceLicensesPageContext} from 'features/products/device-licenses/components/page-context/DeviceLicensesPageContext';
import {
  getLicensingMode,
  isActivationAllowed,
} from 'features/products/device-licenses/components/utils/deviceLicensesUtils';

import ConfirmationDialog from '../confirmation-dialog/ConfirmationDialog';

const DEVICE_OPTIONS_NAMESPACE = 'products.deviceLicenses.components.deviceOptionsButton';
const STATUS_NAMESPACE = 'products.deviceLicenses.deviceDetails.details.label';

const DeviceOptionsButton = ({isSdl, licenseGroupId, onDeviceUpdated}) => {
  const {tableSectionState} = useTableSectionContext();
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const [action, setAction] = useState();
  const {
    frlOnlineUsedLicensesCount,
    totalLicensesCount,
    usedLicensesCount,
    isOverDeploymentAllowed,
  } = useDeviceLicensesPageContext();

  const onDialogDismiss = () => {
    setOpen(false);
  };

  const performDeviceOperation = (selection) => {
    if (tableSectionState.selectedItems.length > 100) {
      // Enforce 100 limit for Activate/Block/Remove bulk operation
      const status = {
        [DeviceAction.ACTIVATE]: intl.formatMessage({id: `${STATUS_NAMESPACE}.activatedDevices`}),
        [DeviceAction.BLOCK]: intl.formatMessage({id: `${STATUS_NAMESPACE}.blockedDevices`}),
        [DeviceAction.REMOVE]: intl.formatMessage({id: `${STATUS_NAMESPACE}.removedDevices`}),
      };
      showError(
        intl.formatMessage(
          {id: `${DEVICE_OPTIONS_NAMESPACE}.limit`},
          {status: status[selection].toLowerCase()}
        )
      );
    } else {
      setAction(selection);
      setOpen(true);
    }
  };

  /**
   * Method areAllKeysDisabled determines whether all the items in the DeviceOptionsButtons will be disabled
   * @returns {boolean} - true if all keys are disabled, false otherwise
   */
  const areAllKeysDisabled = () => {
    const noItems = tableSectionState.items.length === 0;
    const noSelectedItems = tableSectionState.selectedItems.length === 0;
    const noActiveDevices = isSdl ? usedLicensesCount === 0 : frlOnlineUsedLicensesCount === 0;

    if (noItems && noSelectedItems) {
      // no items in list, hence all keys disabled
      return true;
    }

    if (noSelectedItems && noActiveDevices) {
      // there are items but no selected item and no active devices(SDL-usedLicensesCount should be 0, FRL-frlOnlineUsedLicensesCount should be 0)
      return true;
    }

    if (tableSectionState.selectedItems.length > 0) {
      // there is a selected item but each of them are in different state
      const activationState = tableSectionState.selectedItems[0].activationState;
      return !tableSectionState.selectedItems.every(
        (item) => item.activationState === activationState
      );
    }
    return false;
  };

  /**
   * Method that has the logic for enabling or disabling the Device options button
   * @returns {boolean}
   *
   */
  const isDeviceOptionEnabled = () => {
    if (feature.isDisabled('temp_sdl_frl_deactivations')) {
      if (tableSectionState.selectedItems.length === 0) return false;
      const activationState = tableSectionState.selectedItems[0].activationState;
      return tableSectionState.selectedItems.every(
        (item) => item.activationState === activationState
      );
    }
    return !areAllKeysDisabled();
  };

  /**
   * Method that tells if we need to enable the Activate button
   * @returns {boolean}
   */
  const areSelectedBlocked = () =>
    tableSectionState.selectedItems.every(
      (device) => device.activationState === DEVICE_ACTIVATION_STATUS.BLOCKED
    );

  /**
   * A method to determine whether blocked devices can be activated
   * @returns {Boolean} whether blocked devices can be activated
   */
  const getCanActivateLicenses = () =>
    isActivationAllowed({
      isOverDeploymentAllowed,
      selectedBlockedDevice: tableSectionState.selectedItems.length,
      totalLicensesCount,
      usedLicensesCount,
    });

  /**
   * Method that returns an array of keys to be disabled in the Menu
   * - in case there are no used licenses and no items selected, all options are disabled
   * - in case there are selected items, the options are disabled based on the activation state of the selected items
   * - the recover all option is disabled if there are no selected items
   * Note - there can be 0 used licenses, but still there can be more than 1 total licenses(i.e. blocked devices are present)
   *        such cases are to be kept in mind while changing the logic
   * @returns {String[]} - Array of keys
   */
  const getDisabledKeys = () => {
    if (feature.isDisabled('temp_sdl_frl_deactivations')) {
      const disabledActionForBlockedDevices = getCanActivateLicenses()
        ? [DeviceAction.BLOCK]
        : [DeviceAction.ACTIVATE, DeviceAction.BLOCK];
      return areSelectedBlocked()
        ? disabledActionForBlockedDevices
        : [DeviceAction.ACTIVATE, DeviceAction.REMOVE];
    }

    if (areAllKeysDisabled()) {
      return [
        DeviceAction.ACTIVATE,
        DeviceAction.BLOCK,
        DeviceAction.REMOVE,
        DeviceAction.RECOVER_ALL,
      ];
    }
    // state when there is no selected item but there are active devices which can be recovered
    if (tableSectionState.selectedItems.length === 0) {
      return [DeviceAction.ACTIVATE, DeviceAction.BLOCK, DeviceAction.REMOVE];
    }
    // there are selected items, hence recover_all should be disabled
    const disabledActionForDevices = [];

    // cases when there are selections and options have to be determined based on state of selected items
    const disabledActionForBlockedDevices = getCanActivateLicenses()
      ? [DeviceAction.BLOCK]
      : [DeviceAction.ACTIVATE, DeviceAction.BLOCK];
    if (areSelectedBlocked()) {
      disabledActionForDevices.push(...disabledActionForBlockedDevices);
    } else {
      disabledActionForDevices.push(DeviceAction.ACTIVATE, DeviceAction.REMOVE);
    }
    disabledActionForDevices.push(DeviceAction.RECOVER_ALL);
    return disabledActionForDevices;
  };

  let deviceStatuses;
  if (feature.isEnabled('temp_sdl_frl_deactivations')) {
    const commonStatuses = {
      activatedText: {},
      blockedText: {},
      removedText: {},
    };

    deviceStatuses = isSdl
      ? {...commonStatuses, 'recoverAllText.sdl': {}}
      : {
          ...commonStatuses,
          'recoverAllText.frl': {
            goUrl: (linkText) => <GoUrl name="recover-all-licenses-frl">{linkText}</GoUrl>,
          },
        };
  } else {
    deviceStatuses = {activated: {}, blocked: {}, removed: {}};
  }

  return (
    <>
      <Flex alignItems="center" height="size-400" width="size-300">
        <ContextualHelp placement="top middle" variant="info">
          <Heading level={2}>
            {intl.formatMessage({
              id: `${DEVICE_OPTIONS_NAMESPACE}.contextualHelp.heading`,
            })}
          </Heading>
          <Content>
            {Object.keys(deviceStatuses).map((key) => (
              <View key={key} marginBottom="size-50">
                <Text>
                  {intl.formatMessage(
                    {
                      id: `${DEVICE_OPTIONS_NAMESPACE}.contextualHelp.${key}`,
                    },
                    {
                      strong: (str) => <strong>{str}</strong>,
                      ...deviceStatuses[key],
                    }
                  )}
                </Text>
              </View>
            ))}
          </Content>
        </ContextualHelp>
      </Flex>
      <MenuTrigger>
        <Button data-testid="device-options-button" isDisabled={!isDeviceOptionEnabled()}>
          {intl.formatMessage({
            id: `${DEVICE_OPTIONS_NAMESPACE}.label`,
          })}
        </Button>
        <Menu disabledKeys={getDisabledKeys()} onAction={performDeviceOperation} width="size-1600">
          <Item key={DeviceAction.ACTIVATE}>
            {intl.formatMessage({
              id: `${DEVICE_OPTIONS_NAMESPACE}.activate`,
            })}
          </Item>
          <Item key={DeviceAction.BLOCK}>
            {intl.formatMessage({
              id: `${DEVICE_OPTIONS_NAMESPACE}.block`,
            })}
          </Item>
          <Item key={DeviceAction.REMOVE}>
            {intl.formatMessage({
              id: `${DEVICE_OPTIONS_NAMESPACE}.remove`,
            })}
          </Item>
          {feature.isEnabled('temp_sdl_frl_deactivations') && (
            <Item key={DeviceAction.RECOVER_ALL}>
              {intl.formatMessage({
                id: `${DEVICE_OPTIONS_NAMESPACE}.recoverAll`,
              })}
            </Item>
          )}
        </Menu>
      </MenuTrigger>
      <DialogContainer onDismiss={onDialogDismiss}>
        {isOpen && (
          <ConfirmationDialog
            action={action}
            close={onDialogDismiss}
            deviceDetailsList={tableSectionState.selectedItems.map((item) => new Device(item))}
            licenseGroupId={licenseGroupId}
            licensingMode={getLicensingMode(isSdl)}
            onDeviceUpdated={onDeviceUpdated}
          />
        )}
      </DialogContainer>
    </>
  );
};

DeviceOptionsButton.propTypes = {
  /**
   * isSdl - Flag to determine if the product is a shared device license
   */
  isSdl: PropTypes.bool,
  /**
   *  licenseGroupId - Id of the current product profile
   */
  licenseGroupId: PropTypes.string,

  /**
   * Callback for when the device operation was completed
   */
  onDeviceUpdated: PropTypes.func.isRequired,
};

export default DeviceOptionsButton;
