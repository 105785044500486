import {feature, log} from '@admin-tribe/acsc';
import {generatePath} from 'react-router-dom';

import {DIRECTORY_CERTIFICATE_STATUSES} from 'common/entities/DirectoryEntity';
import {getDirectoryListWithCertificates} from 'common/hooks/api/useDirectoryList';
import rootStore from 'core/RootStore';
import {isOrgAdmin} from 'core/organizations/access/organizationAccess';
import {GLOBAL_BANNER_TYPES} from 'features/global-banner/GlobalBannerConstants';
import DirectoryListModel from 'features/settings/models/DirectoryListModel';
import {PATH_SETTINGS_IDENTITY_DIRECTORIES} from 'features/settings/routing/settingsPaths';

/**
 * Method to check if global banners for org level certificates expiration should
 * be shown, and if so will generate payloads to add to the globalBannerStore.
 */
async function notifyCertificatesBanners() {
  if (feature.isEnabled('temp_enable_sys_admin_checks') && !isOrgAdmin()) {
    log.warn('User is NOT the admin. Cannot check for org level certificates expiration.');
    return;
  }
  const directoryListResponse = await getDirectoryListWithCertificates({isGlobal: true});
  const dataModel = DirectoryListModel.fromObject(directoryListResponse);
  let expiredCount = 0;
  let expiringCount = 0;

  dataModel.items?.forEach((directory) => {
    if (directory.status === DIRECTORY_CERTIFICATE_STATUSES.CERTIFICATE_EXPIRED) {
      expiredCount += 1;
    } else if (directory.status === DIRECTORY_CERTIFICATE_STATUSES.CERTIFICATE_EXPIRING) {
      expiringCount += 1;
    }
  });

  const DEFAULT_BANNER_ARGS = {
    buttonText: 'globalBanner.messages.certificates.viewButton',
    buttonUrl: generatePath(PATH_SETTINGS_IDENTITY_DIRECTORIES, {
      orgId: rootStore.organizationStore.activeOrgId,
    }),
    isDismissible: false,
    type: GLOBAL_BANNER_TYPES.ERROR,
  };

  switch (true) {
    case expiredCount > 0 && expiringCount > 0:
      rootStore.organizationStore.globalBannerStore.add({
        ...DEFAULT_BANNER_ARGS,
        message: 'globalBanner.messages.certificates.expiredAndExpiring',
        messageArgs: {
          expiredDirectories: expiredCount,
          expiringDirectories: expiringCount,
        },
      });
      break;
    case expiredCount > 0:
      rootStore.organizationStore.globalBannerStore.add({
        ...DEFAULT_BANNER_ARGS,
        isDismissible: true,
        message: 'globalBanner.messages.certificates.expired2',
        messageArgs: {
          goUrlName: 'aac_certificates',
        },
        removeWithKey: 'expiredCertificates',
        type: GLOBAL_BANNER_TYPES.WARNING,
      });
      break;
    case expiringCount > 0:
      rootStore.organizationStore.globalBannerStore.add({
        ...DEFAULT_BANNER_ARGS,
        message: 'globalBanner.messages.certificates.expiring2',
        messageArgs: {
          goUrlName: 'aac_certificates',
        },
      });
      break;
    default:
  }
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notifyCertificatesBanners};
