import {
  ORGANIZATION_USER_API_QUERY_PARAMS,
  OrganizationUser,
  feature,
  log,
} from '@admin-tribe/acsc';
import {useAsyncModel} from '@pandora/react-async-model';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import UserDetailsModalsWrapper from 'common/components/user-details-content/user-details-modals-wrapper/UserDetailsModalsWrapper';
import rootStore from 'core/RootStore';
import {getManageableAdminRoles} from 'core/admin/access/adminAccess';
import {transformMember} from 'features/users/services/member-transformer/memberTransformerUtils';
import {USER_DETAILS_MODAL_NAMES} from 'features/users/users.constants';

/**
 * Modal wrapper component for editing the products and user groups dialog.
 * Intended to be triggered by the deeplink below:
 * /users/:userId/edit-products-and-groups/:memberId
 * /users/edit-products-and-groups/:memberId
 *
 * @param {Object} props - The component props.
 * @param {Object} props.onCandel - The callback to invoke when the modal's cancel button is pressed.
 * @param {Object} props.onSuccess - The callback to invoke when the modal's success button is pressed.
 */
const EditProductsAndUserGroupsModalWrapper = (props) => {
  const {onCancel, onSuccess} = props;
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;

  const [manageableRoles, setManageableRoles] = useState();
  const [isRolesLoading, setIsRolesLoading] = useState(true);

  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- get url path
  const pathSegments = window?.location.pathname.split('/');
  const memberId = pathSegments[pathSegments.length - 1];

  const fetchOrganizationUser = useCallback(
    async () => {
      let includeList = [
        ORGANIZATION_USER_API_QUERY_PARAMS.PRODUCTS,
        ORGANIZATION_USER_API_QUERY_PARAMS.USER_GROUP_PRODUCTS,
        ORGANIZATION_USER_API_QUERY_PARAMS.USER_CREATION_SOURCE,
        ORGANIZATION_USER_API_QUERY_PARAMS.USER_EDU_ROLE_TAGS,
      ];

      includeList = feature.isEnabled('temp_de_exclusion_40038')
        ? [
            ...includeList,
            ORGANIZATION_USER_API_QUERY_PARAMS.DOMAIN_ENFORCEMENT_EXCLUSION_INDICATOR,
          ]
        : includeList;
      const response = await OrganizationUser.get({
        include: includeList,
        orgId,
        userId: memberId,
      });
      return transformMember({intl, member: response});
    }, // eslint-disable-next-line react-hooks/exhaustive-deps -- no dependencies needed
    []
  );

  const {model: resolvedMember, isLoading, error} = useAsyncModel({loadFn: fetchOrganizationUser});
  const showError = !!error;

  // Get manageable admin roles. These are the roles shown in the 'Add admin' modal.
  useEffect(() => {
    const getAdminRoles = async () => {
      try {
        setIsRolesLoading(true);
        const roles = await getManageableAdminRoles();
        setManageableRoles(roles);
      } catch {
        log.error(`Fetching admin role failed for ${memberId} in org ${orgId}`);
      } finally {
        setIsRolesLoading(false);
      }
    };
    getAdminRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want this to run on setup and takedown, not every render
  }, []); // Runs only once when the component mounts

  return !isLoading && !isRolesLoading && !showError ? (
    <UserDetailsModalsWrapper
      manageableRoles={manageableRoles}
      member={resolvedMember}
      onCancel={onCancel}
      onSuccess={onSuccess}
      openedModal={USER_DETAILS_MODAL_NAMES.EDIT_PRODUCTS_AND_GROUPS}
    />
  ) : null;
};

EditProductsAndUserGroupsModalWrapper.propTypes = {
  /**
   * Optional callback to invoke when the modal's cancel button is pressed.
   */
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default EditProductsAndUserGroupsModalWrapper;
