import {
  PATH_ADD_PRODUCTS,
  PATH_PRODUCTS,
  PATH_PRODUCTS_APP_ADD_ONS,
  PATH_PRODUCTS_APP_INTEGRATIONS,
  PATH_PRODUCTS_APP_INTEGRATIONS_POLICIES,
  PATH_PRODUCTS_APP_INTEGRATIONS_USER_ACCEPTED,
  PATH_PRODUCTS_AUTO_ASSIGN,
  PATH_PRODUCTS_DEVICES,
  PATH_PRODUCTS_DEVICES_DEACTIVATED,
  PATH_PRODUCTS_DEVICES_LEARN_MORE,
  PATH_PRODUCTS_REQUESTS,
  PATH_PRODUCTS_REQUEST_REVIEW,
  PATH_PRODUCT_DETAILS,
  PATH_PRODUCT_DETAILS_ADMINS,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_PRODUCT_DETAILS_DEVICES,
  PATH_PRODUCT_DETAILS_DEVICES_PAYMENT_NEEDED,
  PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED,
  PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED_PERMISSIONS,
  PATH_PRODUCT_DETAILS_ORG_DELEGATABLE_UNLIMITED,
  PATH_PRODUCT_DETAILS_PROFILES,
  PATH_PRODUCT_DETAILS_TRANSACTIONS,
  PATH_PRODUCT_DETAILS_USERS,
  PATH_PRODUCT_GROUP_DETAILS,
  PATH_PRODUCT_PROFILE_DETAILS,
  PATH_PRODUCT_PROFILE_DETAILS_ADMINS,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_PRODUCT_PROFILE_DETAILS_DEVELOPERS,
  PATH_PRODUCT_PROFILE_DETAILS_DEVICES,
  PATH_PRODUCT_PROFILE_DETAILS_INTEGRATIONS,
  PATH_PRODUCT_PROFILE_DETAILS_PERMISSIONS,
  PATH_PRODUCT_PROFILE_DETAILS_USERS,
} from 'features/products/routing/productsPaths';

const productsRouteMigration = [
  {
    stateName: 'products-overview',
    url: PATH_PRODUCTS,
    useReactRoute: true,
  },
  {
    stateName: 'products-overview.add-products',
    url: PATH_ADD_PRODUCTS,
    useReactRoute: true,
  },
  {
    stateName: 'products.app-add-ons',
    url: PATH_PRODUCTS_APP_ADD_ONS,
    useReactRoute: true,
  },
  {
    stateName: 'products.details',
    url: PATH_PRODUCT_DETAILS,
    useReactRoute: true,
  },
  {
    stateName: 'products.details-jobs',
    url: PATH_PRODUCT_DETAILS_BULK_OPERATIONS,
    useReactRoute: true,
  },
  {
    stateName: 'products.details-license-deficit-report',
    url: PATH_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
    useReactRoute: true,
  },
  {
    stateName: 'products.details-jobs',
    url: PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS,
    useReactRoute: true,
  },
  {
    stateName: 'products.details.devices',
    url: PATH_PRODUCT_DETAILS_DEVICES,
    useReactRoute: true,
  },
  {
    stateName: 'products.auto-assignment-rules',
    url: PATH_PRODUCTS_AUTO_ASSIGN,
    useReactRoute: true,
  },
  {
    stateName: '',
    url: '/products/requests',
    useReactRoute: true,
  },
  {
    stateName: 'products.requests',
    url: PATH_PRODUCTS_REQUESTS,
    useReactRoute: true,
  },
  {
    stateName: 'products.details.payment-needed-devices',
    url: PATH_PRODUCT_DETAILS_DEVICES_PAYMENT_NEEDED,
    useReactRoute: true,
  },
  {
    stateName: 'products.devices',
    url: PATH_PRODUCTS_DEVICES,
    useReactRoute: true,
  },
  {
    stateName: 'products.devices.learn-more',
    url: PATH_PRODUCTS_DEVICES_LEARN_MORE,
    useReactRoute: true,
  },
  {
    stateName: 'products.devices.deactivated',
    url: PATH_PRODUCTS_DEVICES_DEACTIVATED,
    useReactRoute: true,
  },
  {
    stateName: 'products.details.feature-restricted',
    url: PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED,
    useReactRoute: true,
  },
  {
    stateName: 'products.details.feature-restricted.permissions',
    url: PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED_PERMISSIONS,
  },
  {
    stateName: 'products.details.profiles',
    url: PATH_PRODUCT_DETAILS_PROFILES,
    useReactRoute: true,
  },
  {
    stateName: 'products.details.users',
    url: PATH_PRODUCT_DETAILS_USERS,
    useReactRoute: true,
  },
  {
    stateName: 'products.details.admins',
    url: PATH_PRODUCT_DETAILS_ADMINS,
    useReactRoute: true,
  },
  {
    stateName: 'products.details.transactions',
    url: PATH_PRODUCT_DETAILS_TRANSACTIONS,
    useReactRoute: true,
  },
  {
    stateName: 'products.details.org-delegatable-unlimited',
    url: PATH_PRODUCT_DETAILS_ORG_DELEGATABLE_UNLIMITED,
    useReactRoute: true,
  },
  {
    stateName: 'products.profile-details',
    url: PATH_PRODUCT_PROFILE_DETAILS,
    useReactRoute: true,
  },
  {
    stateName: 'products.profile-details-jobs',
    url: PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS,
    useReactRoute: true,
  },
  {
    stateName: 'products.profile-details-license-deficit-report',
    url: PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
    useReactRoute: true,
  },
  {
    stateName: 'products.profile-details-results',
    url: PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS,
    useReactRoute: true,
  },
  {
    stateName: 'products.profile-details.devices',
    url: PATH_PRODUCT_PROFILE_DETAILS_DEVICES,
    useReactRoute: true,
  },
  {
    stateName: ['products.profile-details.permissions', 'products.profile-details.permissions2'],
    url: PATH_PRODUCT_PROFILE_DETAILS_PERMISSIONS,
    useReactRoute: true,
  },
  {
    stateName: 'products.profile-details.admins',
    url: PATH_PRODUCT_PROFILE_DETAILS_ADMINS,
    useReactRoute: true,
  },
  {
    stateName: 'products.profile-details.developers',
    url: PATH_PRODUCT_PROFILE_DETAILS_DEVELOPERS,
    useReactRoute: true,
  },
  {
    stateName: 'products.profile-details.integrations',
    url: PATH_PRODUCT_PROFILE_DETAILS_INTEGRATIONS,
    useReactRoute: true,
  },
  {
    stateName: 'products.profile-details.users',
    url: PATH_PRODUCT_PROFILE_DETAILS_USERS,
    useReactRoute: true,
  },
  {
    stateName: 'products.application-integrations',
    url: PATH_PRODUCTS_APP_INTEGRATIONS,
    useReactRoute: true,
  },
  {
    stateName: 'products.application-integrations.policies',
    url: PATH_PRODUCTS_APP_INTEGRATIONS_POLICIES,
    useReactRoute: true,
  },
  {
    stateName: 'products.application-integrations.user-accepted',
    url: PATH_PRODUCTS_APP_INTEGRATIONS_USER_ACCEPTED,
    useReactRoute: true,
  },
  {
    stateName: ['products.product-groups.details', 'products.product-groups.details.products'],
    url: PATH_PRODUCT_GROUP_DETAILS,
    useReactRoute: true,
  },
  {
    stateName: '',
    url: PATH_PRODUCTS_REQUEST_REVIEW,
    useReactRoute: 'temp_request_access_deep_link',
  },
];

export default productsRouteMigration;
