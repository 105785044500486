import {feature} from '@admin-tribe/acsc';
import React from 'react';

import {createRoutesForTabs} from 'common/utils/routerUtils';
import {canViewAutoAssignmentRules, canViewProducts} from 'core/products/access/productAccess';
import {buildAccessCheckLoader} from 'core/router/loaders';
import {populateAddProductsModalDataLoader} from 'core/router/sharedLoaders';
import BulkOperationsPage from 'features/bulk-operations/pages/bulk-operations-page/BulkOperationsPage';
import JobResultsPage from 'features/bulk-operations/pages/job-results-page/JobResultsPage';
import LicenseDeficitReportPage from 'features/bulk-operations/pages/license-deficit-report-page/LicenseDeficitReportPage';
import AppIntegrationsPage from 'features/products/app-integrations/pages/AppIntegrationsPage';
import FeatureRestrictedPage from 'features/products/device-licenses/pages/feature-restricted/FeatureRestrictedPage';
import ProductAppAddOnsPage from 'features/products/pages/app-add-ons/ProductAppAddOnsPage';
import AutoAssignmentRulesPage from 'features/products/pages/auto-assignment-rules-page/AutoAssignmentRulesPage';
import ProductDevicesLearnMorePage from 'features/products/pages/learn-more/ProductDevicesLearnMorePage';
import LegacyDevicesPage from 'features/products/pages/legacy-devices/LegacyDevicesPage';
import ProductDeactivatedDevicesPage from 'features/products/pages/legacy-devices/product-deactivated-devices-page/ProductDeactivatedDevicesPage';
import OrgDelegatableUnlimitedProductPage from 'features/products/pages/org-delegatable-unlimited-product-page/OrgDelegatableUnlimitedProductPage';
import AllProductsPage from 'features/products/pages/product-all-products-page/AllProductsPage';
import ProductGroupsPageManager from 'features/products/pages/product-groups-page-manager/ProductGroupsPageManager';
import ProductPageManager from 'features/products/pages/product-page-manager/ProductPageManager';
import ProductProfileDetailsPage from 'features/products/pages/product-profile-details-page/ProductProfileDetailsPage';
import ProductRequestsPage from 'features/products/pages/requests/ProductRequestsPage';
import ProductRequestsPageTnoWrapper from 'features/products/pages/requests/ProductRequestsPageTnoWrapper';
import RequestModalWrapper from 'features/products/pages/requests/RequestModalWrapper';
import {
  appIntegrationLoader,
  dblLoader,
  productAdminsLoader,
  productBulkOperationsLoader,
  productDetailsLoader,
  productDevicesDetailsLoader,
  productGroupsLoader,
  productOrgDelegatableUnlimitedLoader,
  productProfileAdminsLoader,
  productProfileDetailsLoader,
  productProfileDetailsRedirectLoader,
  productProfileDevelopersLoader,
  productProfileDevicesLoader,
  productProfilePermissionsLoader,
  productProfileUsersLoader,
  productTransactionsLoader,
} from 'features/products/routing/loaders/productsRoutesLoaders';
import ProductsWorkspace from 'features/products/workspace/ProductsWorkspace';

import {
  PATH_PRODUCTS,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED_PERMISSIONS,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_SEGMENT_ADD_PRODUCTS,
  PATH_SEGMENT_APP_INTEGRATIONS,
  PATH_SEGMENT_APP_INTEGRATIONS_POLICIES,
  PATH_SEGMENT_APP_INTEGRATIONS_USER_ACCEPTED,
  PATH_SEGMENT_AUTO_ASSIGN,
  PATH_SEGMENT_DEVICES,
  PATH_SEGMENT_DEVICES_DEACTIVATED,
  PATH_SEGMENT_DEVICES_LEARN_MORE,
  PATH_SEGMENT_DEVICES_PAYMENT_NEEDED,
  PATH_SEGMENT_FEATURE_RESTRICTED,
  PATH_SEGMENT_PRODUCTS_APP_ADD_ONS,
  PATH_SEGMENT_PRODUCT_DETAILS,
  PATH_SEGMENT_PRODUCT_DETAILS_ADMINS,
  PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS,
  PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_SEGMENT_PRODUCT_DETAILS_DEVICES,
  PATH_SEGMENT_PRODUCT_DETAILS_ORG_DELEGATABLE_UNLIMITED,
  PATH_SEGMENT_PRODUCT_DETAILS_PROFILES,
  PATH_SEGMENT_PRODUCT_DETAILS_TRANSACTIONS,
  PATH_SEGMENT_PRODUCT_DETAILS_USERS,
  PATH_SEGMENT_PRODUCT_GROUP_DETAILS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_ADMINS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_DEVELOPERS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_DEVICES,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_INTEGRATIONS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_PERMISSIONS,
  PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_USERS,
  PATH_SEGMENT_PRODUCT_REQUESTS,
  PATH_SEGMENT_PRODUCT_REQUEST_REVIEW,
} from './productsPaths';

/** Get app integration related routes. Everything under `/:orgId/products/application-integrations`. */
function getAppIntegrationRoutes() {
  return [
    {
      children: [
        ...createRoutesForTabs({
          element: <AppIntegrationsPage />,
          routes: [
            {path: PATH_SEGMENT_APP_INTEGRATIONS_POLICIES},
            {path: PATH_SEGMENT_APP_INTEGRATIONS_USER_ACCEPTED},
          ],
        }),
      ],
      loader: appIntegrationLoader,
      path: PATH_SEGMENT_APP_INTEGRATIONS,
    },
  ];
}

/** Get app integration and add ons routes. Everything under `/:orgId/products/app-add-ons`. */
function getAppAddOnsRoutes() {
  return [
    {
      element: <ProductAppAddOnsPage />,
      path: PATH_SEGMENT_PRODUCTS_APP_ADD_ONS,
    },
  ];
}

/** Get device-based licensing related routes. Everything under `/:orgId/products/devices`. */
function getDBLRoutes() {
  return [
    {
      children: [
        {
          element: <ProductDevicesLearnMorePage />,
          path: PATH_SEGMENT_DEVICES_LEARN_MORE,
        },
        {
          element: <ProductDeactivatedDevicesPage />,
          path: PATH_SEGMENT_DEVICES_DEACTIVATED,
        },
      ],
      loader: dblLoader,
      path: PATH_SEGMENT_DEVICES,
    },
  ];
}

/** Get product detail related routes. Everything under `/:orgId/products/:productId`. */
function getProductDetailsRoutes() {
  /** Everything under /:orgId/products/:productId/bulk-operations */
  const productBulkOpsRoutes = [
    {
      element: <BulkOperationsPage path={PATH_PRODUCT_DETAILS_BULK_OPERATIONS} />,
      loader: productBulkOperationsLoader,
      path: PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS,
    },
    {
      element: <JobResultsPage path={PATH_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS} />,
      loader: productBulkOperationsLoader,
      path: PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS_RESULTS,
    },
    {
      element: (
        <LicenseDeficitReportPage
          path={PATH_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT}
        />
      ),
      loader: productBulkOperationsLoader,
      path: PATH_SEGMENT_PRODUCT_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
    },
  ];

  /** Everything under /:orgId/products/:productId/profiles */
  const productProfileRoutes = [
    {
      children: [
        ...createRoutesForTabs({
          element: <ProductProfileDetailsPage />,
          routes: [
            {
              loader: productProfileAdminsLoader,
              path: PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_ADMINS,
            },
            {
              loader: productProfileDevelopersLoader,
              path: PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_DEVELOPERS,
            },
            {
              loader: productProfileDevicesLoader,
              path: PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_DEVICES,
            },
            {
              loader: productProfileDevelopersLoader,
              path: PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_INTEGRATIONS,
            },
            {
              loader: productProfilePermissionsLoader,
              path: PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_PERMISSIONS,
            },
            {
              loader: productProfileUsersLoader,
              path: PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_USERS,
            },
          ],
        }),
      ],
      loader: productProfileDetailsRedirectLoader,
      path: PATH_SEGMENT_PRODUCT_PROFILE_DETAILS,
    },
    {
      element: <BulkOperationsPage path={PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS} />,
      path: PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS,
    },
    {
      element: <JobResultsPage path={PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS} />,
      path: PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_RESULTS,
    },
    {
      element: (
        <LicenseDeficitReportPage
          path={PATH_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT}
        />
      ),
      path: PATH_SEGMENT_PRODUCT_PROFILE_DETAILS_BULK_OPERATIONS_LICENSE_DEFICIT_REPORT,
    },
  ];

  return [
    {
      children: [
        ...createRoutesForTabs({
          element: <FeatureRestrictedPage />,
          routes: [
            {
              path: PATH_SEGMENT_FEATURE_RESTRICTED,
            },
            {
              path: PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED_PERMISSIONS,
            },
          ],
        }),
        ...createRoutesForTabs({
          element: <LegacyDevicesPage />,
          routes: [
            {
              loader: productDevicesDetailsLoader,
              path: PATH_SEGMENT_DEVICES_PAYMENT_NEEDED,
            },
            {
              loader: productDevicesDetailsLoader,
              path: PATH_SEGMENT_PRODUCT_DETAILS_DEVICES,
            },
          ],
        }),
        ...createRoutesForTabs({
          element: <ProductPageManager />,
          routes: [
            {
              loader: productProfileDetailsLoader,
              path: PATH_SEGMENT_PRODUCT_DETAILS_PROFILES,
            },
            {
              loader: productProfileUsersLoader,
              path: PATH_SEGMENT_PRODUCT_DETAILS_USERS,
            },
            {
              loader: productAdminsLoader,
              path: PATH_SEGMENT_PRODUCT_DETAILS_ADMINS,
            },
            {
              loader: productTransactionsLoader,
              path: PATH_SEGMENT_PRODUCT_DETAILS_TRANSACTIONS,
            },
          ],
        }),
        {
          element: <OrgDelegatableUnlimitedProductPage />,
          loader: productOrgDelegatableUnlimitedLoader,
          path: PATH_SEGMENT_PRODUCT_DETAILS_ORG_DELEGATABLE_UNLIMITED,
        },
        ...productBulkOpsRoutes,
        ...productProfileRoutes,
      ],
      loader: productDetailsLoader,
      path: PATH_SEGMENT_PRODUCT_DETAILS,
      shouldRevalidate: () => true, // Fixes an issue when navigating from a product profile page to the product details page via breadcrumbs
    },
  ];
}

/** Get product group routes (e.g. DX instances, Stock credit packs, etc.). Everything under `/:orgId/products/groups`.*/
function getProductGroupRoutes() {
  return [
    {
      element: <ProductGroupsPageManager />,
      loader: productGroupsLoader,
      path: PATH_SEGMENT_PRODUCT_GROUP_DETAILS,
    },
  ];
}

/** All product access automation routes (JIT/RA pages) */
function getAutoAssignmentRoutes() {
  const isTnoEnabled = feature.isEnabled('temp_tno_rejectproductassign_modal');
  return [
    {
      element: <AutoAssignmentRulesPage />,
      loader: buildAccessCheckLoader(canViewAutoAssignmentRules),
      path: PATH_SEGMENT_AUTO_ASSIGN,
    },
    {
      children: [
        {
          element: <RequestModalWrapper />,
          loader: buildAccessCheckLoader(canViewAutoAssignmentRules),
          path: PATH_SEGMENT_PRODUCT_REQUEST_REVIEW,
        },
      ],
      element: isTnoEnabled ? <ProductRequestsPageTnoWrapper /> : <ProductRequestsPage />,
      loader: buildAccessCheckLoader(canViewAutoAssignmentRules),
      path: PATH_SEGMENT_PRODUCT_REQUESTS,
    },
  ];
}

/** Gets all of the products section routes */
function productsRoutes() {
  return [
    {
      children: [
        {element: <AllProductsPage />, index: true},
        ...getAppAddOnsRoutes(),
        ...getAppIntegrationRoutes(),
        ...getAutoAssignmentRoutes(),
        ...getDBLRoutes(),
        ...getProductDetailsRoutes(),
        ...getProductGroupRoutes(),
        {
          element: <AllProductsPage />,
          id: 'products.add-products',
          loader: populateAddProductsModalDataLoader,
          path: PATH_SEGMENT_ADD_PRODUCTS,
        },
      ],
      element: <ProductsWorkspace />,
      loader: buildAccessCheckLoader(canViewProducts),
      path: PATH_PRODUCTS,
    },
  ];
}

export {getProductDetailsRoutes, getProductGroupRoutes, getAutoAssignmentRoutes};

export default productsRoutes;
