import {PageContext, feature, navBus} from '@admin-tribe/acsc';
import {
  Page,
  PageActions,
  PageBanners,
  PageContent,
  PageHeader,
  PageInfoBar,
  getProductDisplayName,
} from '@admin-tribe/acsc-ui';
import {Item, TabList, TabPanels, Tabs} from '@adobe/react-spectrum';
import {DEVICE_LICENSING_MODES} from '@pandora/react-data-source-device-activation';
import {ImageIcon} from '@pandora/react-image-icon';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useLocation, useParams} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import rootStore from 'core/RootStore';
import DeviceList from 'features/products/device-licenses/components/device-list/DeviceList';
import useTotalDevices from 'features/products/device-licenses/components/hooks/useTotalDevices';
import DeviceLicensesInfoBar from 'features/products/device-licenses/components/info-bar/DeviceLicensesInfoBar';
import DeviceLicensesPageActions from 'features/products/device-licenses/components/page-actions/DeviceLicensesPageActions';
import DeviceLicensesPageBanner from 'features/products/device-licenses/components/page-banner/DeviceLicensesPageBanner';
import {DeviceLicensesPageContextProvider} from 'features/products/device-licenses/components/page-context/DeviceLicensesPageContext';
import {
  getViewHeaderData,
  isActivationAllowed,
} from 'features/products/device-licenses/components/utils/deviceLicensesUtils';
import ProductPermissions from 'features/products/product-profiles/product-profile-permissions/ProductPermissions';
import {
  PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED,
  PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED_PERMISSIONS,
} from 'features/products/routing/productsPaths';

const HEADER_NAMESPACE = 'products.deviceLicenses';
const FRL_TABS_KEYS = {
  DEVICES: 'Devices',
  PERMISSIONS: 'Permissions',
};
const findTabByKey = (tabs, key) => tabs.find((tab) => tab.key === key);
function findKeyByPath(location) {
  if (
    feature.isEnabled('pa_8073_ac_egress_ip_restriction') &&
    location.pathname.toLowerCase().includes(FRL_TABS_KEYS.PERMISSIONS.toLowerCase())
  ) {
    return FRL_TABS_KEYS.PERMISSIONS;
  }
  return FRL_TABS_KEYS.DEVICES;
}
/**
 * Page for managing FRL Devices
 */
const FeatureRestrictedPage = ({pageContext}) => {
  const intl = useIntl();
  const [triggerToRefetchFrlDevices, setTriggerToRefetchFrlDevices] = React.useState(false);
  const orgId = rootStore.organizationStore.activeOrgId;
  const location = useLocation();
  const [selectedKey, setSelectedKey] = React.useState(findKeyByPath(location));
  const angularProductId = pageContext?.targetId;
  const reactProductId = useParams()?.productId;
  const productId = angularProductId || reactProductId;

  const product = rootStore.organizationStore.productList.items.find(
    (item) => item.id === productId
  );
  const productDisplayName = getProductDisplayName(intl, product);

  useDocumentTitle({defaultTitle: productDisplayName});

  const isFrlOnline = product.fulfillableItemList.hasPackagePreconditioningForFRLOnline();
  const isFrl = product.isFeatureRestrictedLicense(product.fulfillableItemList);

  const tabs = useMemo(
    () => [
      {
        key: FRL_TABS_KEYS.DEVICES,
        pathname: generatePath(PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED, {orgId, productId}),
      },
      {
        key: FRL_TABS_KEYS.PERMISSIONS,
        pathname: generatePath(PATH_PRODUCT_DETAILS_FEATURE_RESTRICTED_PERMISSIONS, {
          orgId,
          productId,
        }),
      },
    ],
    [orgId, productId]
  );

  const {activatedDevices, frlOnlineUsedLicensesCount, isError, isLoading, devices} =
    useTotalDevices({
      productId,
      triggerToRefetchFrlDevices,
      ...(feature.isEnabled('temp_sdl_frl_deactivations') && {
        licensingMode: DEVICE_LICENSING_MODES.FRL_ONLINE,
      }),
    });

  if (!product.isFeatureRestrictedLicense()) {
    navBus.navigate('/');
    return null;
  }

  const {showBanner, totalLicensesCount, usedLicensesCount, totalDevicesCount, earliestExpiryDate} =
    getViewHeaderData({
      activatedDevices,
      devices,
      intl,
      isLoading,
      product,
    });
  const isOverDeploymentAllowed = product.fulfillableItemList.hasOverdelegationAllowed();

  const onChange = (key) => {
    if (key === selectedKey) {
      // Currently on the tab that was clicked, don't navigate
      return;
    }
    const tab = findTabByKey(tabs, key);

    // Just modify the URL, the key will get updated in the useEffect above
    if (tab) {
      navBus.pushState({url: tab.pathname});
      setSelectedKey(tab.key);
    }
  };

  return (
    <Page data-testid="device-licenses-page" isBumpered={isError} isLoading={isLoading}>
      <PageBanners>
        <DeviceLicensesPageBanner namespace={HEADER_NAMESPACE} showBanner={showBanner} />
      </PageBanners>

      <PageHeader title={getProductDisplayName(intl, product)}>
        <ImageIcon alt="" size="M" src={product.getIcon?.()} />
      </PageHeader>

      <PageActions>
        <DeviceLicensesPageActions namespace={HEADER_NAMESPACE} product={product} />
      </PageActions>

      <PageInfoBar>
        <DeviceLicensesInfoBar
          earliestExpiryDate={earliestExpiryDate}
          isFrl={isFrl}
          namespace={HEADER_NAMESPACE}
          showTotalDevices={isFrlOnline}
          totalDevicesCount={totalDevicesCount}
          totalLicensesCount={totalLicensesCount}
          usedLicensesCount={usedLicensesCount}
        />
      </PageInfoBar>
      <PageContent>
        {isFrlOnline && (
          <Tabs
            aria-label={intl.formatMessage({id: `${HEADER_NAMESPACE}.tabs.ariaLabel`})}
            defaultSelectedKey={selectedKey}
            onSelectionChange={feature.isEnabled('pa_8073_ac_egress_ip_restriction') && onChange}
          >
            <TabList>
              <Item key={FRL_TABS_KEYS.DEVICES}>
                {feature.isEnabled('temp_sdl_frl_deactivations')
                  ? intl.formatMessage({
                      id: `${HEADER_NAMESPACE}.devicesPlaceholder.onlineonly`,
                    })
                  : intl.formatMessage({
                      id: `${HEADER_NAMESPACE}.devicesPlaceholder`,
                    })}
              </Item>
              {feature.isEnabled('pa_8073_ac_egress_ip_restriction') && (
                <Item key={FRL_TABS_KEYS.PERMISSIONS}>
                  {intl.formatMessage({
                    id: `products.productProfiles.tab.permissions.title`,
                  })}
                </Item>
              )}
            </TabList>
            <TabPanels>
              <Item key={FRL_TABS_KEYS.DEVICES}>
                <DeviceLicensesPageContextProvider
                  value={{
                    canActivateDevices: isActivationAllowed({
                      isOverDeploymentAllowed,
                      totalLicensesCount,
                      usedLicensesCount: frlOnlineUsedLicensesCount,
                    }),
                    frlOnlineUsedLicensesCount,
                    isOverDeploymentAllowed,
                    productId,
                    refetchHeaderData: () =>
                      setTriggerToRefetchFrlDevices(!triggerToRefetchFrlDevices),
                    totalDevicesCount,
                    totalLicensesCount,
                    usedLicensesCount,
                  }}
                >
                  <DeviceList
                    isSdl={product.isSharedDeviceLicense()}
                    productId={productId}
                    // This showIPAddress flag is used to show/hide IP address. The ip address is not part of the device data attributes that are being sent back for FRL
                    showIPAddress={false}
                    totalLicensesCount={totalLicensesCount}
                  />
                </DeviceLicensesPageContextProvider>
              </Item>
              {feature.isEnabled('pa_8073_ac_egress_ip_restriction') && (
                <Item key={FRL_TABS_KEYS.PERMISSIONS}>
                  <ProductPermissions product={product} />
                </Item>
              )}
            </TabPanels>
          </Tabs>
        )}
      </PageContent>
    </Page>
  );
};

FeatureRestrictedPage.propTypes = {
  /**
   * The PageContext object used to determine the product id (targetId)
   */
  pageContext: PropTypes.instanceOf(PageContext),
};

export default FeatureRestrictedPage;
