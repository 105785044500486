import {EN_DASH, TableActions, useTableSectionContext} from '@pandora/react-table-section';
import PropTypes from 'prop-types';
import React from 'react';

import DeviceOptionsButton from 'features/products/device-licenses/components/device-options-button/DeviceOptionsButton';
import DeviceExportCsvMenu from 'features/products/device-licenses/components/export-csv-menu/DeviceExportCsvMenu';
import {useDeviceLicensesPageContext} from 'features/products/device-licenses/components/page-context/DeviceLicensesPageContext';

const DeviceListActions = ({isSdl, licenseGroupId, productId}) => {
  const {tableSectionState, tableSectionUtils} = useTableSectionContext();
  const {refetchHeaderData, totalDevicesCount} = useDeviceLicensesPageContext();

  // For FRL product types, we do not show these columns in the CSV report
  const FRL_EXCLUDED_COLUMNS = ['packageId', 'packageName', 'licenseConfigId'];

  return (
    <TableActions>
      <DeviceOptionsButton
        isSdl={isSdl}
        licenseGroupId={licenseGroupId}
        onDeviceUpdated={() => {
          refetchHeaderData();
          tableSectionUtils.modifyTableItems({modifiedItemKeys: []});
        }}
        productId={productId}
      />
      <DeviceExportCsvMenu
        // The presence of an licenseGroupId would qualify this product as non-FRL
        excludedColumns={licenseGroupId ? [] : FRL_EXCLUDED_COLUMNS}
        getSelectedRows={() => tableSectionState.selectedItems}
        isDisabled={totalDevicesCount === 0 || totalDevicesCount === EN_DASH}
        isSdl={isSdl}
        licenseGroupId={licenseGroupId}
        productId={productId}
      />
    </TableActions>
  );
};

DeviceListActions.displayName = 'DeviceListActions';

DeviceListActions.propTypes = {
  /**
   * isSdl - Flag to determine if the product is a shared device license
   */
  isSdl: PropTypes.bool,

  /**
   *  licenseGroupId - Id of the current product profile
   */
  licenseGroupId: PropTypes.string,

  /**
   *  productId - Id of the current product
   */
  productId: PropTypes.string.isRequired,
};

export default DeviceListActions;
