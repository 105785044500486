import {
  ACTIONBLOCK_ID,
  CAMPAIGN_ID,
  CLICKABLE_CARD_LABELS,
  CONTAINER_ID,
  CONTROL_GROUP_ID,
  EVENT_ACTION,
  INTERACTION,
  SOPHIA_EVENT_NAME,
  SURFACE_ID,
  TREATMENT_ID,
  VARIATION_ID,
  dispatchUiEventAnalytics,
} from '@admin-tribe/acsc';
import camelCase from 'lodash/camelCase';
import pick from 'lodash/pick';

import SophiaCardList from './SophiaCardList';
import SOPHIA_CONSTANTS from './SophiaConstants';
import SophiaContentList from './SophiaContentList';
import SophiaContextualParams from './SophiaContextualParams';

/**
 * @description populate eventAction based on a sophia card element content and interaction method e.g. use the label from the primary cta button.
 * @param {Object} card - card object with display information
 * @param {String} action - action performed e.g. 'primary'
 * @returns {String} containing eventAction values. defaults to eventAction: 'click'
 */
function getEventActionFromInteraction(card, action) {
  // create event action from button labels, defaults to 'click'
  let eventAction;
  switch (action) {
    case INTERACTION.PRIMARY:
      eventAction = card?.[CLICKABLE_CARD_LABELS.PRIMARY];
      break;
    case INTERACTION.SECONDARY:
      eventAction = card?.[CLICKABLE_CARD_LABELS.SECONDARY];
      break;
    case INTERACTION.DISMISS:
      eventAction = EVENT_ACTION.DISMISS;
      break;
    default:
      eventAction = EVENT_ACTION.CLICK;
      break;
  }

  return eventAction;
}

/**
 * Constructs an impression string based on the buttons available on a given Sophia card
 * @param {Object} card - The Sophia Card to interpret for impression data
 * @param {Object} [card.ctaLabel] - The primary button label, if available
 * @param {Object} [card.secondaryCTALabel] - The secondary button label, if available
 * @returns {String} The impression string that represents this card in analytics events
 */
function getImpressionFromCard(card) {
  const {ANALYTICS_ID} = SOPHIA_CONSTANTS;
  const impression = [];

  if (card?.[CLICKABLE_CARD_LABELS.PRIMARY]) {
    impression.push(`${card.ctaLabel}|${ANALYTICS_ID}`);
  }

  if (card?.[CLICKABLE_CARD_LABELS.SECONDARY]) {
    impression.push(`${card.secondaryCTALabel}|${ANALYTICS_ID}`);
  }

  return impression.length > 0 ? impression.join(',') : undefined;
}

/**
 * @description Calls Sophia to get the list of cards with the given contextual params.
 *
 * @param {Object} contextualParamsOptions - options to pass to the SophiaContextualParams.
 * @param {String} [surfaceId] - the surfaceId to pass to Sophia. Default is SURFACE_ID.ONE_CONSOLE.
 * @returns {Promise} returns a promise resolved with the SophiaCardList.
 */
async function getSophiaCards({
  contextualParamsOptions,
  surfaceId = SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE,
}) {
  const contextualParams = await SophiaContextualParams.get(contextualParamsOptions);
  return SophiaCardList.get({
    contextualParams,
    surfaceID: surfaceId, // note difference in case
  });
}

/**
 * @description Calls Sophia to get the list of content with the given contextual params.
 *
 * @param {Object} contextualParamsOptions - options to pass to the SophiaContextualParams.
 * @param {String} [surfaceId=SURFACE_ID.ONE_CONSOLE] - the surfaceId to pass to Sophia.
 * @returns {Promise} returns a promise resolved with the SophiaContentList.
 */
async function getSophiaContent({
  contextualParamsOptions,
  surfaceId = SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE,
}) {
  const contextualParams = await SophiaContextualParams.get(contextualParamsOptions);
  return SophiaContentList.get({
    contextualParams,
    surfaceID: surfaceId,
  });
}

/**
 * @description Method to determine if the given Sophia card indicates an active Sophia renewal campaign.
 *
 * @param {Card} card - a Sophia card.
 * @returns {Boolean} returns true if the given card indicates Sophia has an active renewal campaign.
 */
function isDefaultRenewalCampaign(card) {
  return (
    card.getCardId() ===
    // Due to time constraints, we store the cancellation header locally instead of creating it in AEM (Adobe
    // Experience Manager).
    // In order to use Sophia to decide who to show the banner to, we return an AEM card from the Sophia campaign.
    // The current setup is to return a fake AEM card and the UI checks whether the returned card matches the fake.
    // If it does, we use the local header string.
    '/content/help/en/ccx/v1/offers/trial/width/2/segment/default/oneconsole-chat-for-renewal-fake'
  );
}

/**
 * @description Method to dispatch an Analytic event when a Sophia card is displayed.
 *
 * @param {Array} cards - an array of Sophia cards.
 * @param {Integer} [index] - the index of the card in cards. Default is 0.
 */
function onDisplayCard(cards, index = 0) {
  const card = cards?.[index];

  const cardData = [card.getAnalyticsParams()];

  dispatchDisplayEvent({
    responses: cardData,
  });
}

/**
 *
 * @description dispatch an Action:display name:sophiaBannerLoad event containing a sophia cards metadata
 * @param {Object} options - list of param to customize the dispatched event
 * @param {Array} options.responses - array containing data sent to Adobe Analytics
 * @param {String} [options.cardIdKey] - represents the value in a response that will be mapped to cardId during SophiaDescriptor construction
 */
function dispatchDisplayEvent(options) {
  const {cardIdKey, responses} = options;

  const impression = getImpressionFromCard(responses?.[0]);

  dispatchUiEventAnalytics({
    eventAction: EVENT_ACTION.LOADED,
    eventName: SOPHIA_EVENT_NAME.SOPHIA_BANNER_LOAD,
    interaction: {impression},
    sophia: {cardIdKey, responses},
  });
}

/**
 * @description Filter card data and dispatch an analytic event when a sophia card is interacted with.
 * @param {Object} options - customizable params for sending interaction analytics.
 * @param {String} [options.action] - name relating to point of interaction e.g. primary click key 'ctaLabel' defaults to 'click'.
 * @param {Object} options.card - card data to be filtered and dispatched to analytics.
 */
function sendInteractionAnalytics({action, card, cardIdKey}) {
  const {ANALYTICS_ID} = SOPHIA_CONSTANTS;

  // don't get analytics params for hva sophia cards as they are filtered earlier
  let cardAnalyticsData;
  if (cardIdKey) {
    cardAnalyticsData = [card];
  } else {
    cardAnalyticsData = [card.getAnalyticsParams()];
  }

  // hva sophia cards do not have a nested cards
  const nestedCard = card?.card || card;

  const eventAction = getEventActionFromInteraction(nestedCard, action);

  dispatchUiEventAnalytics({
    eventAction,
    eventName: SOPHIA_EVENT_NAME.SOPHIA_BANNER_CLICK,
    interaction: {
      click: `${eventAction}|${ANALYTICS_ID}`,
    },
    sophia: {
      cardIdKey,
      responses: cardAnalyticsData,
    },
  });
}

/**
 * @description clean up punctuation and spaces, then camelCase a string
 * @param {Object} str - the string to clean
 * @returns {String} the cleanup up string
 */
function cleanAndCamelCase(str) {
  if (!str) {
    return '';
  }
  // Remove all non-alphanumeric characters (except underscores for compatibility) and convert to camelCase
  const cleanedInput = str.replace(/\W/g, ' ');
  return camelCase(cleanedInput);
}

const getSophiaPromoAnalytics = (sophiaResponse, content) => {
  const analyticsParams = pick(sophiaResponse?.containerAnalyticsParams, [
    ACTIONBLOCK_ID,
    CAMPAIGN_ID,
    CONTROL_GROUP_ID,
    TREATMENT_ID,
    VARIATION_ID,
  ]);

  const responseParams = pick(sophiaResponse, [CONTAINER_ID, SURFACE_ID]);

  const contentParams = {analyticsId: content?.analyticsId || '0'};

  const actionParams = {
    [CLICKABLE_CARD_LABELS.PRIMARY]: cleanAndCamelCase(content?.primaryCTALabel),
    [CLICKABLE_CARD_LABELS.SECONDARY]: cleanAndCamelCase(content?.secondaryCTALabel),
  };

  return {...analyticsParams, ...contentParams, ...responseParams, ...actionParams};
};

/**
 * Constructs an impression string based on the buttons available on a given Sophia card
 * @param {Object} analytics - The Sophia analytics data interpret for impression data
 * @param {Object} [analytics.primaryCTALabel] - The primary button label, if available
 * @param {Object} [analytics.secondaryCTALabel] - The secondary button label, if available
 * @returns {String} The impression string that represents this card in analytics events
 */
function getSophiaImpression(analytics) {
  const {ANALYTICS_ID} = SOPHIA_CONSTANTS;
  const analyticsId = analytics?.analyticsId || '0';
  const impression = [];

  if (analytics?.primaryCTALabel) {
    impression.push(
      `${cleanAndCamelCase(analytics.primaryCTALabel)}|${analyticsId}|${ANALYTICS_ID}`
    );
  }

  if (analytics?.secondaryCTALabel) {
    impression.push(
      `${cleanAndCamelCase(analytics.secondaryCTALabel)}|${analyticsId}|${ANALYTICS_ID}`
    );
  }

  return impression.length > 0 ? impression.join(',') : undefined;
}

/**
 *
 * @description dispatch a load event for the sophia promo panel
 * @param {Object} analytics - object of analytics data
 */
function dispatchSophiaPromoLoadEvent(analytics) {
  const impression = getSophiaImpression(analytics);

  // we need to reuse the banner load event action, so that server side Analytics processing can understand this is a RENDER event
  // Also eventAction actually is not used as the Analytics eventAction, it's the eventName hence feels wrong way round
  dispatchUiEventAnalytics({
    eventAction: 'sophiaPromoPanel',
    eventName: SOPHIA_EVENT_NAME.SOPHIA_BANNER_LOAD,
    interaction: {impression},
    sophia: {
      responses: [analytics],
    },
  });
}

/**
 * @description populate eventAction based on sophia content and interaction method e.g. use the label from the
 * primary cta button.
 * @param {String} action - action performed e.g. 'primary'
 * @param {Object} analytics - analytics object with action mapping information
 * @returns {String} containing eventAction values. defaults to eventAction: 'click'
 */
function getEventActionFromSophiaInteraction(action, analytics) {
  let eventAction;
  switch (action) {
    case INTERACTION.PRIMARY:
      eventAction = cleanAndCamelCase(
        analytics?.[CLICKABLE_CARD_LABELS.PRIMARY] || INTERACTION.PRIMARY
      );
      break;
    case INTERACTION.SECONDARY:
      eventAction = cleanAndCamelCase(
        analytics?.[CLICKABLE_CARD_LABELS.SECONDARY] || INTERACTION.SECONDARY
      );
      break;
    case INTERACTION.DISMISS:
      eventAction = EVENT_ACTION.DISMISS;
      break;
    default:
      eventAction = EVENT_ACTION.CLICK;
      break;
  }

  return eventAction;
}

/**
 * @description Dispatch an analytic event when a sophia promo is interacted with.
 * @param {String} ctaAction - name relating to point of interaction e.g. primary click key 'ctaLabel' defaults to 'click'.
 * @param {Object} analytics - card data to be filtered and dispatched to analytics.
 */
function dispatchSophiaPromoInteractEvent({ctaAction, analytics}) {
  const {ANALYTICS_ID} = SOPHIA_CONSTANTS;

  const impressionEventAction = getEventActionFromSophiaInteraction(ctaAction, analytics);
  const analyticsId = analytics?.analyticsId || '0';

  // we need to reuse the banner click event action, so that server side Analytics processing can understand this is a CLICK event.
  // Also eventAction actually is not used as the Analytics eventAction, it's the eventName hence feels wrong way round
  dispatchUiEventAnalytics({
    eventAction: 'sophiaPromoPanel',
    eventName: SOPHIA_EVENT_NAME.SOPHIA_BANNER_CLICK,
    interaction: {
      click: `${impressionEventAction}|${analyticsId}|${ANALYTICS_ID}`,
    },
    sophia: {
      responses: [analytics || {}],
    },
  });
}

const sophiaHelper = {
  dispatchDisplayEvent,
  dispatchSophiaPromoInteractEvent,
  dispatchSophiaPromoLoadEvent,
  getEventActionFromSophiaInteraction,
  getImpressionFromCard,
  getSophiaCards,
  getSophiaContent,
  getSophiaImpression,
  getSophiaPromoAnalytics,
  isDefaultRenewalCampaign,
  onDisplayCard,
  sendInteractionAnalytics,
};

export default sophiaHelper;
