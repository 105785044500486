import {LicenseConfigurations, Product, log} from '@admin-tribe/acsc';
import {Subpage, showError, showSuccess} from '@admin-tribe/acsc-ui';
import {useAsyncModel} from '@pandora/react-async-model';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import EditProductProfilePermissionsModal from './edit-product-profile-permissions/EditProductProfilePermissionsModal';
import PermissionsTableList from './permissions-tables/PermissionsTableList';
import {getFilteredConfigurations} from './productProfilePermissionsUtils';

/**
 * The subpage for the product/license configuration permissions.
 */
const ProductPermissions = observer(({product}) => {
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;
  const [editModalState, setEditModalState] = useState({
    configurationGroups: [],
    configurationIndex: null,
    initialGroupId: null,
    isOpen: false,
    sectionId: null,
  });
  const [isSaving, setIsSaving] = useState(false);

  const fetchLicenseConfigurations = useCallback(
    () =>
      LicenseConfigurations.get({
        orgId,
        productId: product.id,
      }),
    [orgId, product.id]
  );

  const {
    error: licenseConfigurationsError,
    isLoading: isLicenseConfigurationsLoading,
    model: licenseConfigurations,
  } = useAsyncModel({
    loadFn: fetchLicenseConfigurations,
  });

  const filteredItems = getFilteredConfigurations(licenseConfigurations);

  const onSaveEditModal = async (updatedGroups) => {
    setIsSaving(true);
    setEditModalState({...editModalState, isOpen: false});
    try {
      licenseConfigurations.configurations[editModalState.configurationIndex].sections.find(
        (section) => section.id === editModalState.sectionId
      ).content = updatedGroups;
      await licenseConfigurations.save();

      showSuccess(
        intl.formatMessage({id: 'products.productProfilePermissions.table.edit.saveSuccess'})
      );
    } catch (error) {
      log.error(error);
      licenseConfigurations.restore();
      showError(
        intl.formatMessage({id: 'products.productProfilePermissions.table.edit.saveError'})
      );
    } finally {
      setIsSaving(false);
      setEditModalState({
        configurationGroups: [],
        configurationIndex: null,
        initialGroupId: null,
        isOpen: false,
        sectionId: null,
      });
    }
  };

  const onPermissionRowEditClicked = (
    configurationGroups,
    groupId,
    sectionId,
    configurationIndex
  ) => {
    setEditModalState({
      configurationGroups,
      configurationIndex,
      initialGroupId: groupId,
      isOpen: true,
      sectionId,
    });
  };

  const onCloseEditModal = () => {
    setEditModalState({
      configurationGroups: [],
      configurationIndex: null,
      initialGroupId: null,
      isOpen: false,
      sectionId: null,
    });
  };

  return (
    <Subpage
      data-testid="product-profile-permissions-subpage"
      isBumpered={!!licenseConfigurationsError}
      isLoading={isLicenseConfigurationsLoading || isSaving}
    >
      {licenseConfigurations && (
        <>
          <PermissionsTableList
            configurations={filteredItems}
            onPermissionRowEditClicked={onPermissionRowEditClicked}
          />
          <EditProductProfilePermissionsModal
            configurationGroups={editModalState.configurationGroups}
            initialGroupId={editModalState.initialGroupId}
            isOpen={editModalState.isOpen}
            licenseGroupName={product.shortName}
            onClose={onCloseEditModal}
            onSave={onSaveEditModal}
            productName={product.shortName}
          />
        </>
      )}
    </Subpage>
  );
});

ProductPermissions.propTypes = {
  /**
   * The Product model.
   */
  product: PropTypes.instanceOf(Product),
};

export default ProductPermissions;
