import {feature} from '@admin-tribe/acsc';
import {useStore} from '@admin-tribe/acsc-ui';
import {
  ActionButton,
  ActionGroup,
  Cell,
  Column,
  DialogContainer,
  Flex,
  Item,
  Row,
  TableBody,
  TableHeader,
  Text,
  Tooltip,
  TooltipTrigger,
  View,
} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import {useModalDialog} from '@pandora/react-modal-dialog';
import {Table, TableFilters, useTableSectionContext} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import CopyCheckSumButton from 'common/components/copy-checksum-button/CopyCheckSumButton';
import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';
import {usePregeneratedPackagesPageContext} from 'features/packages/pages/pregenerated-packages/PregeneratedPackagesPageContext';
import CreatePackageService from 'features/packages/services/CreatePackageService';
import {isNotELearningTemplate} from 'features/packages/services/PackageSummaryDrawerUtils';
import PackagesEntitlementsService from 'features/packages/services/PackagesEntitlementsService';
import CreatePackageModalStore from 'features/packages/stores/CreatePackageModalStore';
import CustomizeIcon from 'features/packages/svgs/CustomizeIcon.svg';
import DownloadIcon from 'features/packages/svgs/DownloadIcon.svg';

import CreatePackageModal from '../create-package-modal/CreatePackageModal';
import {CreatePackageModalContextProvider} from '../create-package-modal/CreatePackageModalContext';

import styles from './SingleAppTemplateListTable.pcss';

/**
 * Single App Templates list table.
 */
const SingleAppTemplateListTable = observer(() => {
  const {tableSectionState} = useTableSectionContext();
  const {packagesAdobeTemplatesStore} = usePregeneratedPackagesPageContext();
  const createPackageModalStore = useStore(() => new CreatePackageModalStore());

  const {isOpen, openModal, closeModal} = useModalDialog();

  const intl = useIntl();

  const getProductToDisplay = useCallback((item) => {
    let productToDisplay;
    if (item.data.nativeProductsList) {
      if (
        item.data.nativeProductsList.length === 1 &&
        item.data.nativeProductsList[0].sapCode === 'KCCC'
      ) {
        return item.data.nativeProductsList[0];
      }
      // pick the non KCCC product
      productToDisplay = item.data.nativeProductsList.find((element) => element.sapCode !== 'KCCC');
    }
    return productToDisplay;
  }, []);

  const getImageUrl = useCallback((item) => {
    const imageUrl = null;
    const productToDisplay = getProductToDisplay(item);
    if (productToDisplay) {
      return productToDisplay.getIconUrl();
    }
    return imageUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- first render only
  }, []);

  const getProductVersion = useCallback((item) => {
    const productVersion = null;
    const productToDisplay = getProductToDisplay(item);
    if (productToDisplay) {
      return productToDisplay.version;
    }
    return productVersion;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- first render only
  }, []);

  const getProductStorage = useCallback(
    (item) => {
      let productStorageSize = null;
      if (item.data.nativePackageSize) {
        productStorageSize = intl.formatMessage(
          {id: 'packages.summaryDrawerBody.packageDetails.size.value'},
          {
            size: (item.data.nativePackageSize / 1024 / 1024 / 1024).toFixed(2),
          }
        );
      }
      return productStorageSize;
    },
    [intl]
  );

  const getLanguage = () => (
    <>
      {intl.formatMessage(
        {id: 'packages.summaryDrawerBody.packageDetails.locale'},
        {language: 'en_US'}
      )}
      {intl.formatMessage({
        id: 'packages.summaryDrawerBody.packageDetails.matchOSLocale',
      })}
    </>
  );

  const getPlatform = (item) =>
    intl.formatMessage(
      {
        id: `packages.allPackages.platform.${item.data.platform}${
          item.data.platform === CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM ? item.data.bits : ''
        }`,
      },
      {
        nowrap: (string) => <Text UNSAFE_style={{whiteSpace: 'nowrap'}}>{string}</Text>,
      }
    );

  const onCustomisePress = (item) => {
    CreatePackageService.setCreatePackageFactoryValuesForTemplate(
      {...item.data, isSingleApp: true},
      PackagesEntitlementsService.hasCcEntitlement && !item.data.shouldNotAddCCD,
      isNotELearningTemplate(item.data)
    );
    createPackageModalStore.setStoreValuesforCreatePackageOpen();
    openModal();
  };

  const checkForEmpty = (val) => {
    if (!val) {
      packagesAdobeTemplatesStore.search(null);
    }
  };

  const renderTableItem = (item) => (
    <Row key={item.data.id} data-testid={`item${item.data.id}`}>
      <Cell>
        <Flex alignItems="center">
          <ImageIcon
            alt=""
            data-testid={`product-image${item.data.id}`}
            paddingEnd="size-125"
            size="M"
            src={getImageUrl(item)}
          />
          <View paddingRight="size-125" />
          {item.data.name}
        </Flex>
      </Cell>
      <Cell data-testid={`product-version${item.data.id}`}>{getProductVersion(item)}</Cell>
      <Cell data-testid={`product-storage${item.data.id}`}>{getProductStorage(item)}</Cell>
      <Cell data-testid={`product-platform${item.data.id}`}>{getPlatform(item)}</Cell>
      <Cell data-testid={`product-language${item.data.id}`}>{getLanguage()}</Cell>
      <Cell>
        <Flex gap="size-125" justifyContent="end">
          <TooltipTrigger delay={0}>
            <ActionButton
              aria-label={intl.formatMessage({
                id: 'packages.pregeneratedPackages.customize',
              })}
              data-testid={`customize${item.data.id}`}
              isQuiet
              onPress={() => onCustomisePress(item)}
              UNSAFE_style={{backgroundColor: 'var(--spectrum-global-color-gray-200)'}}
              variant="action"
            >
              <ImageIcon
                alt={intl.formatMessage({id: 'packages.pregeneratedPackages.customize'})}
                size="M"
                src={CustomizeIcon}
              />
            </ActionButton>
            <Tooltip>
              {intl.formatMessage({
                id: 'packages.pregeneratedPackages.customize',
              })}
            </Tooltip>
          </TooltipTrigger>

          <CopyCheckSumButton
            aria-label={intl.formatMessage({
              id: 'packages.pregeneratedPackages.copyCheckSumButton.copyChecksum',
            })}
            value={item.data.nativePackageSHA256}
          />

          <View
            UNSAFE_style={{
              backgroundColor: 'var(--spectrum-global-color-blue-600)',
              borderRadius: 'var(--spectrum-global-dimension-size-100)',
            }}
          >
            <TooltipTrigger delay={0}>
              <ActionButton
                aria-label={intl.formatMessage({
                  id: 'packages.pregeneratedPackages.download',
                })}
                data-testid={item.data.id}
                isQuiet
                onPress={() => item.data.downloadNativePackage(true)}
                staticColor="white"
              >
                <ImageIcon
                  alt={intl.formatMessage({id: 'packages.pregeneratedPackages.download'})}
                  data-testid={`download${item.data.id}`}
                  size="M"
                  src={DownloadIcon}
                />
              </ActionButton>
              <Tooltip>
                {intl.formatMessage({
                  id: 'packages.pregeneratedPackages.download',
                })}
              </Tooltip>
            </TooltipTrigger>
          </View>
        </Flex>
      </Cell>
    </Row>
  );

  const renderTable = () => {
    const tableJsx = (
      <Table
        aria-label={intl.formatMessage({id: 'packages.adobeTemplates.title'})}
        minWidth={feature.isEnabled('temp_packages_pregenerated_page_fixes') ? 1000 : ''}
        noItemsFoundContentMessage={null}
        noItemsFoundHeadingMessage={intl.formatMessage({
          id: 'packages.pregeneratedPackages.table.empty.heading',
        })}
        noSearchResultsContentMessage={intl.formatMessage({
          id: 'packages.pregeneratedPackages.table.empty.noSearchResults.content',
        })}
        noSearchResultsHeadingMessage={intl.formatMessage({
          id: 'packages.pregeneratedPackages.table.empty.noSearchResults.heading',
        })}
        serverErrorContentMessage={intl.formatMessage({
          id: 'packages.adobeTemplates.table.empty.networkError.content',
        })}
        serverErrorHeadingMessage={intl.formatMessage({
          id: 'packages.adobeTemplates.table.empty.networkError.heading',
        })}
      >
        <TableHeader>
          <Column key="appName">
            <FormattedMessage id="packages.adobeTemplates.table.headerLabels.appName" />
          </Column>
          <Column key="version" width="10%">
            <FormattedMessage id="packages.adobeTemplates.table.headerLabels.version" />
          </Column>
          <Column key="storage" width="10%">
            <FormattedMessage id="packages.summaryDrawerBody.packageDetails.size" />
          </Column>
          <Column key="platformString">
            <FormattedMessage id="packages.adobeTemplates.table.headerLabels.platform" />
          </Column>
          <Column key="language">
            <FormattedMessage id="packages.adobeTemplates.table.headerLabels.language" />
          </Column>
          <Column key="action" align="end">
            <FormattedMessage id="packages.adobeTemplates.table.headerLabels.action" />
          </Column>
        </TableHeader>
        <TableBody items={tableSectionState.items}>{(item) => renderTableItem(item)}</TableBody>
      </Table>
    );

    if (feature.isEnabled('temp_packages_pregenerated_page_fixes')) {
      return <div className={styles['table-component']}>{tableJsx}</div>;
    }
    return tableJsx;
  };

  return (
    <>
      <div className={styles['search-bar-row']}>
        <Flex alignItems="center" gap="size-100" justifyContent="end">
          <ActionGroup
            data-testid="platform-selector-button"
            isQuiet
            onAction={packagesAdobeTemplatesStore.handlePlatformChange}
            selectedKeys={[packagesAdobeTemplatesStore.selectedPlatform]}
            selectionMode="single"
            // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- need to add some extra styling as per specs
            UNSAFE_className={styles['platform-selector']}
          >
            <Item key={CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM}>
              {intl.formatMessage({id: 'packages.allPackages.platform.WIN'})}
            </Item>
            <Item key={CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM}>
              {intl.formatMessage({
                id: 'packages.tools.adminTools.downloadPlatforms.macUniversal',
              })}
            </Item>
          </ActionGroup>
          <div className={styles['search-field']}>
            <TableFilters
              aria-label={intl.formatMessage({
                id: 'products.addProductProfileModal.productsPage.searchLabel',
              })}
              hasSearch
              inputMode="search"
              isQuiet
              minLength={1}
              onChange={checkForEmpty}
              placeholder={intl.formatMessage({
                id: 'products.addProductProfileModal.productsPage.searchLabel',
              })}
            />
          </div>
        </Flex>
      </div>

      {!packagesAdobeTemplatesStore.isLoading &&
        !packagesAdobeTemplatesStore.isSingleAppPageLoading &&
        renderTable()}

      <DialogContainer onDismiss={closeModal}>
        {isOpen && (
          <CreatePackageModalContextProvider createPackageModalStore={createPackageModalStore}>
            <CreatePackageModal onCancel={closeModal} />
          </CreatePackageModalContextProvider>
        )}
      </DialogContainer>
    </>
  );
});

SingleAppTemplateListTable.displayName = 'SingleAppTemplateListTable';
export default SingleAppTemplateListTable;
