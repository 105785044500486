import {
  Product,
  compareDates,
  feature,
  hasLegacyDeviceLicenses,
  hasSomeSelectedFulfillableItems,
} from '@admin-tribe/acsc';
import {getProductDisplayName} from '@admin-tribe/acsc-ui';
import uniqBy from 'lodash/uniqBy';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {
  canViewAutoAssignmentRules,
  canViewDeviceLicenses,
} from 'core/products/access/productAccess';
import trialHelper from 'core/products/trial-helper/trialHelper';
import {getProductContracts, sortProducts} from 'core/products/utils/productUtils';
import {getValidKey} from 'core/services/product/product-group/product-list/FigIdSwitchFallBackUtil';
import {shouldShowProductOrProductGroup} from 'features/overview/components/products-summary-section/productsSummarySectionUtils';
import {
  PATH_PRODUCTS_APP_ADD_ONS,
  PATH_PRODUCTS_APP_INTEGRATIONS_POLICIES,
  PATH_PRODUCTS_AUTO_ASSIGN,
  PATH_PRODUCTS_DEVICES_DEACTIVATED,
  PATH_PRODUCTS_DEVICES_LEARN_MORE,
  PATH_PRODUCTS_REQUESTS,
} from 'features/products/routing/productsPaths';

const APP_INTEGRATION_NAV_ORDER = 10000;
const AUTO_ASSIGNMENT_NAV_ORDER = 10001;
const DEVICE_LICENSE_NAV_ORDER = 999999;
const PRODUCT_REQUESTS_NAV_ORDER = 10001;
const PRODUCT_APP_ADD_ONS_NAV_ORDER = 10002;

/**
 * @description method returns auto assignment rules nav items
 * @param {String} orgId orgId
 * @param {Object} intl function to internationalize the strings
 * @returns {Array} contains the navItems for auto assignment rules
 */
const getAutoAssignmentRulesNavItems = (orgId, intl) => {
  const autoAssignmentNavItems = [];
  const isAddAnalysisId = feature.isEnabled('temp_add_analysis_id_to_product_nav');
  if (canViewAutoAssignmentRules()) {
    autoAssignmentNavItems.push({
      ...(isAddAnalysisId ? {analysisId: 'nav-auto-assignment-rules'} : {}),
      group: intl.formatMessage({id: 'products.advancedSettings.title'}),
      href: generatePath(PATH_PRODUCTS_AUTO_ASSIGN, {
        orgId,
      }),
      name: intl.formatMessage({id: 'products.autoAssignmentRules.title'}),
      order: AUTO_ASSIGNMENT_NAV_ORDER,
      ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-auto-assignment-rules'} : {}),
    });
  }
  return autoAssignmentNavItems;
};

/**
 *
 * @param {ContractList} contractList - Contract list to pick from.
 * @param {Product | ProductGroupProductList} productOrProductGroup - Object
 *     whose respective contracts will be fetched.
 * @returns {Array<Contract>} Contracts that match the provided product or
 *     product group product list.
 */
const getContractsForProductOrProductGroup = (contractList, productOrProductGroup) =>
  productOrProductGroup instanceof Product
    ? getProductContracts(productOrProductGroup, contractList)
    : getProductGroupContracts(productOrProductGroup, contractList);

/**
 * @description method returns device licenses for legacy device product
 * @param {ContractList} contractList to check
 * @param {ProductList} productList to check
 * @param {string} orgId orgId
 * @param {Object} intl - react intl
 * @returns {Array} contains the navItems for deviceLicense
 */
const getDeviceLicensesNavItems = (orgId, intl) => {
  const deviceLicenseNavItems = [];
  if (canViewDeviceLicenses()) {
    const hasDeviceLicenseProduct = hasLegacyDeviceLicenses(
      rootStore.organizationStore.productList
    );
    const isAddAnalysisId = feature.isEnabled('temp_add_analysis_id_to_product_nav');
    const analysisId = hasDeviceLicenseProduct
      ? 'nav-device-license-deactivated'
      : 'nav-device-license-learn-more';
    deviceLicenseNavItems.push({
      ...(isAddAnalysisId ? {analysisId} : {}),
      group: intl.formatMessage({
        id: 'products.productsWorkspaceNav.navItem.deviceLicenses',
      }),
      href: generatePath(
        hasDeviceLicenseProduct
          ? PATH_PRODUCTS_DEVICES_DEACTIVATED
          : PATH_PRODUCTS_DEVICES_LEARN_MORE,
        {
          orgId,
        }
      ),
      name: intl.formatMessage({
        id: `products.productsWorkspaceNav.navItem.${
          hasDeviceLicenseProduct ? 'deactivatedDevices' : 'deviceLicensesLearnMore'
        }`,
      }),
      order: hasDeviceLicenseProduct ? DEVICE_LICENSE_NAV_ORDER : 1,
      ...(feature.isEnabled('temp_navitem_testids') ? {testId: analysisId} : {}),
    });
  }
  return deviceLicenseNavItems;
};

/**
 * @description Prime Method to return the nav list for the products
 * @param {Object} products orgId products
 * @param {Object} intl function to internationalize the strings
 * @returns {{navItems: {}[]}} contains the navItems for products and figGroupProducts
 */
const getProductsNavList = (products, intl) => {
  const filteredProducts = products.items.filter(
    (product) =>
      product.isAdministerable() && !product.fulfillableItemList.hasOrgOnDemandConsumable()
  );
  const {nonMarketingCloudProducts} = getMarketingCloudProductGroups(filteredProducts);
  const sortedRemainingProducts = nonMarketingCloudProducts.sort(
    (a, b) => a.cloud?.localeCompare(b.cloud) || a.longName?.localeCompare(b.longName)
  );
  const navItems = sortedRemainingProducts
    .map((product, index) => {
      if (product.isLegacyDeviceLicense()) {
        return createProductNavItem({
          intl,
          order: index + 1,
          product,
        });
      }

      return undefined;
    })
    .filter((navItem) => navItem !== undefined);

  return {navItems};
};

/**
 * @description method returns product req nav item
 * @param {orgId} orgId orgId
 * @param {Object} intl function to internationalize the strings
 * @returns {Array} contains the navItems for products requests
 */
const getProductRequestsNavItems = (orgId, intl) => {
  const productRequestNavItems = [];
  if (canViewAutoAssignmentRules()) {
    const isAddAnalysisId = feature.isEnabled('temp_add_analysis_id_to_product_nav');
    productRequestNavItems.push({
      ...(isAddAnalysisId ? {analysisId: 'nav-product-requests'} : {}),
      group: intl.formatMessage({id: 'products.advancedSettings.title'}),
      href: generatePath(PATH_PRODUCTS_REQUESTS, {
        orgId,
      }),
      name: intl.formatMessage({id: 'products.requests.title'}),
      order: PRODUCT_REQUESTS_NAV_ORDER,
      ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-product-requests'} : {}),
    });
  }
  return productRequestNavItems;
};

/**
 * @description method returns product app add ons nav item
 * @param {orgId} orgId orgId
 * @param {Object} intl object to internationalize the strings
 * @returns {Array} contains the navItems for products app add ons
 */
const getAppAddOnNavItems = (orgId, intl, productList) => {
  const appAddOnNavItems = [];
  // only show add ons page if org has a product that has any of the specified FIs selected
  const requiredSelectedFis = [
    {code: 'spark'},
    {code: 'spark_video'},
    {code: 'spark_post'},
    {code: 'spark_page'},
  ];
  const isAddAnalysisId = feature.isEnabled('temp_add_analysis_id_to_product_nav');
  if (hasSomeSelectedFulfillableItems(productList, requiredSelectedFis)) {
    appAddOnNavItems.push({
      ...(isAddAnalysisId ? {analysisId: 'nav-app-integrations'} : {}),
      group: intl.formatMessage({id: 'products.integrationsAndAddOns.title'}),
      href: generatePath(PATH_PRODUCTS_APP_INTEGRATIONS_POLICIES, {
        orgId,
      }),
      name: intl.formatMessage({id: 'products.productsWorkspaceNav.navItem.appIntegrations'}),
      order: APP_INTEGRATION_NAV_ORDER,
      ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-app-integrations'} : {}),
    });
    appAddOnNavItems.push({
      ...(isAddAnalysisId ? {analysisId: 'nav-app-add-ons'} : {}),
      group: intl.formatMessage({id: 'products.integrationsAndAddOns.title'}),
      href: generatePath(PATH_PRODUCTS_APP_ADD_ONS, {
        orgId,
      }),
      name: intl.formatMessage({id: 'products.appAddOns.title'}),
      order: PRODUCT_APP_ADD_ONS_NAV_ORDER,
      ...(feature.isEnabled('temp_navitem_testids') ? {testId: 'nav-app-add-ons'} : {}),
    });
  }
  return appAddOnNavItems;
};

/**
 * @description Helper to get all the products and product groups and sorted.
 * @param {Object} options - Top level wrapper object.
 * @param {Object} options.collator - Collator used for sorting
 * @param {Intl} options.intl - Intl object used for sorting by name
 *
 * @returns {Array<Product | ProductGroupProductList>} Array of Products or
 *     ProductGroupProductLists sorted by product name
 */
const getSortedProductsAndProductGroups = ({collator, intl}) => {
  const productsAndProductGroups = rootStore.organizationStore.orgConsumables
    .getProductsAndProductGroups()
    .filter(shouldShowProductOrProductGroup);
  return sortProducts({collator, intl, products: productsAndProductGroups});
};

/////////

function createProductNavItem({intl, order, product}) {
  const navItemOptions = {
    cloud: product.cloud,
    group: 'products.deviceLicenses',
    name: getDisplayNameForNav(product, intl),
    order,
    pattern: '/:orgId/products/:productId/',
    state: {
      name: 'products.details',
      params: {
        productId: product.id,
      },
    },
  };

  return navItemOptions;
}

function getDisplayNameForNav(product, intl) {
  if (trialHelper.isTrialProduct(product)) {
    return {
      title: 'products.productsNavigation.trial',
      translateValues: {
        name: getProductDisplayName(intl, product),
      },
    };
  }
  return getProductDisplayName(intl, product);
}

function getMarketingCloudProductGroups(products) {
  const marketingCloudProducts = products.filter((product) => product.isMarketingCloudProduct());
  const nonMarketingCloudProducts = products.filter(
    (product) => !product.isMarketingCloudProduct()
  );
  return getProductGroupProductLists(marketingCloudProducts, nonMarketingCloudProducts);
}

function getProductGroupContracts(productGroup, contractList) {
  const flattenedResults = productGroup.items
    .map((product) => getProductContracts(product, contractList))
    .flat();

  const uniqueContracts = uniqBy(flattenedResults, 'id');
  const sortContractsByStartDate = (a, b) => compareDates(a.getStartDate(), b.getStartDate());
  return uniqueContracts.sort(sortContractsByStartDate);
}

function getProductGroupProductLists(marketingCloudProducts, nonMarketingCloudProducts) {
  const productGroupProductLists = [];

  if (feature.isEnabled('temp_switch_figid_to_ai_code')) {
    const productGroupId = getValidKey();
    const figProducts = marketingCloudProducts.reduce((group, product) => {
      const productGroupValue = product[productGroupId];
      group[productGroupValue] = group[productGroupValue] ?? [];
      group[productGroupValue].push(product);
      return group;
    }, {});
    Object.keys(figProducts).forEach((idValue) =>
      figProducts[idValue].length > 1
        ? productGroupProductLists.push({items: figProducts[idValue], [productGroupId]: idValue})
        : nonMarketingCloudProducts.push(figProducts[idValue][0])
    );
  } else {
    const figProducts = marketingCloudProducts.reduce((group, product) => {
      const {figId} = product;
      group[figId] = group[figId] ?? [];
      group[figId].push(product);
      return group;
    }, {});
    Object.keys(figProducts).forEach((figId) =>
      figProducts[figId].length > 1
        ? productGroupProductLists.push({figId, items: figProducts[figId]})
        : nonMarketingCloudProducts.push(figProducts[figId][0])
    );
  }

  return {nonMarketingCloudProducts, productGroupProductLists};
}

export {
  getAppAddOnNavItems,
  getAutoAssignmentRulesNavItems,
  getContractsForProductOrProductGroup,
  getDeviceLicensesNavItems,
  getProductsNavList,
  getProductRequestsNavItems,
  getSortedProductsAndProductGroups,
};
